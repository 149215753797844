import { Box, Button, Checkbox, Chip, FormControlLabel, Grid2, Typography } from '@mui/material'
import React from 'react'
import roomImgMain from "../../assets/hotelmain1.png"
import roomImg1 from "../../assets/hotelside1.png"
import roomImg2 from "../../assets/hotelside2.png"
import roomImg3 from "../../assets/hotelside3.png"
import AcUnitIcon from '@mui/icons-material/AcUnit';
import TvIcon from '@mui/icons-material/Tv';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import WifiIcon from '@mui/icons-material/Wifi';
import {useNavigate} from 'react-router-dom'

const HotelViewComponent = () => {
    const navigate=useNavigate()
  return (
    <Box sx={{m:2,width:"70%"}}>
    <Box>
        <Typography sx={{color:"#000000",fontSize:"32px"}}>Diamond Room</Typography>
        <Typography sx={{color:"#9F9F9F",fontSize:"20px",mb:1}}>Rated by thousands of happy customers </Typography>
    </Box>
    <Grid2 container spacing={2}>
        <Grid2 size={{md:8}}>
            <Box sx={{width:"100%",height:"556px"}}>
                <img src={roomImgMain} alt="Diamond Room" style={{width:"100%",height:"100%"}}/>
            </Box>

        </Grid2>
        <Grid2 size={{md:4}} spacing={2} >
            <Grid2 size={{md:12}} sx={{height:"174px",width:"100%",mb:2}}>
            <img src={roomImg1}  sx={{height:"100%",width:"100%"}} alt="Diamond Room" style={{width:"100%",height:"100%"}}/>
            </Grid2>
            <Grid2 size={{md:12}}  sx={{height:"174px",width:"100%",mb:2}}>
            <img src={roomImg2}  sx={{height:"100%",width:"100%"}} alt="Diamond Room" style={{width:"100%",height:"100%"}}/>
            </Grid2>
            <Grid2 size={{md:12}}  sx={{height:"174px",width:"100%"}}>
            <img src={roomImg3} sx={{height:"100%",width:"100%"}} alt="Diamond Room" style={{width:"100%",height:"100%"}}/>
            </Grid2>
        </Grid2>
    </Grid2>
    <Grid2 container spacing={2} mt={2}>
        <Grid2 size={{md:8}}>
            <Typography sx={{color:"#00000",fontSize:"28px"}}>Luxury 5 Star Room</Typography>
            <Typography sx={{color:"#9F9F9F",fontSize:"16px"}}>2000 Fashion Show Drive, Las Vegas, NV, US</Typography>
        </Grid2>
        <Grid2 size={{md:4}}>
            <Box>
                <Button sx={{backgroundColor:"#F32120",color:"#fff",width:"100%",py:1}}>Only 1 Room Left for this Role</Button>
            </Box>
        </Grid2>
    </Grid2>
    <Grid2 container spacing={2} mt={2}>
        <Grid2 size={{md:6}}>
        <Typography sx={{color:"rgba(0, 0, 0, 0.8)",fontSize:"20px",fontWeight:"600",mb:2}}>Property highlights</Typography>
        <Grid2 container spacing={2} sx={{width:"100%"}}>
            <Grid2 size={{md:6}}>
                <Box sx={{display:"flex",alignItems:"center",gap:"1rem"}}>
                    <AcUnitIcon sx={{color:"rgba(0, 0, 0, 0.8)"}} />
                    <Typography  sx={{color:"rgba(0, 0, 0, 0.8)",fontSize:"14px"}}>Air conditioning</Typography>
                </Box>
            </Grid2>
            <Grid2 size={{md:6}}>
                <Box sx={{display:"flex",alignItems:"center",gap:"1rem"}}>
                    <TvIcon sx={{color:"rgba(0, 0, 0, 0.8)"}} />
                    <Typography  sx={{color:"rgba(0, 0, 0, 0.8)",fontSize:"14px"}}>Cable TV</Typography>
                </Box>
            </Grid2>
            <Grid2 size={{md:6}}>
                <Box sx={{display:"flex",alignItems:"center",gap:"1rem"}}>
                    <LocalParkingIcon sx={{color:"rgba(0, 0, 0, 0.8)"}} />
                    <Typography  sx={{color:"rgba(0, 0, 0, 0.8)",fontSize:"14px"}}>Free parking</Typography>
                </Box>
            </Grid2>
            <Grid2 size={{md:6}}>
                <Box sx={{display:"flex",alignItems:"center",gap:"1rem"}}>
                    <WifiIcon sx={{color:"rgba(0, 0, 0, 0.8)"}} />
                    <Typography  sx={{color:"rgba(0, 0, 0, 0.8)",fontSize:"14px"}}>Free Wi-Fi</Typography>
                </Box>
            </Grid2>
        </Grid2>
    
        
        </Grid2>
        <Grid2 size={{md:6}}>
        <Typography sx={{color:"rgba(0, 0, 0, 0.8)",fontSize:"20px",fontWeight:"600"}}>Room Facilities</Typography>
        <Typography  sx={{color:"rgba(0, 0, 0, 0.8)",fontSize:"14px",mt:2}}>
        Kingsize doublebed, Bathtub, Rainshower with seperate hand shower, Airconditioning, Desk and sitting area, Television, Phone, Nespresso coffee machine, Coffee and tea facilities, Safe, Small fridge, Iron and Ironingboard, Care products (including shampoo, conditioner, shower gel), Blowdryer
        </Typography>
         
        </Grid2>
    </Grid2>
    <Grid2 container spacing={2} mt={4} sx={{borderBottom:"2px solid #C9C9C9",pb:2}}>
        <Grid2 size={{md:3}}>
            <Typography sx={{color:"#000000",fontSize:"18px",fontWeight:"medium"}}>Type</Typography>
        </Grid2>
        <Grid2 size={{md:3}}>
        <Typography sx={{color:"#000000",fontSize:"18px",fontWeight:"medium"}}>Breakfast</Typography>
        </Grid2>
        <Grid2 size={{md:3}}>
        <Typography sx={{color:"#000000",fontSize:"18px",fontWeight:"medium"}}>Total</Typography>
        </Grid2>
    </Grid2>
    <Grid2 container spacing={2} mt={4} sx={{borderBottom:"2px solid #C9C9C9",pb:2}}>
        <Grid2 size={{md:3}}>
        <Chip label="Refundable" sx={{border:"1px dotted #0086EC",backgroundColor:'#F6FBFF',borderRadius:"5px",color:"#0086EC"}} />
        </Grid2>
        <Grid2 size={{md:3}}>
            <Box sx={{display:"flex",alignItems:'center'}}>
            <FormControlLabel control={<Checkbox defaultChecked />} label="Includes Breakfast" />
            <Typography sx={{color:"#F37120"}}>&euro; 40,00</Typography>
            </Box>
            <Typography sx={{fontSize:"10px",color:"#969BA0",ml:3}}>(€ 20,00 x 2 days)</Typography>
      
        </Grid2>
        <Grid2 size={{md:3}}>
        <Typography sx={{color:"#F37120",fontSize:"18px",fontWeight:"medium"}}>&euro;100,00</Typography>
        </Grid2>
        <Grid2 size={{md:3}}>
      <Button sx={{backgroundColor:"#F37120",color:"#fff",width:"116px",height:"45px"}} onClick={()=>navigate('/bookNowFormHotel')} >Book now</Button>
        </Grid2>

    </Grid2>
    <Grid2 container spacing={2} mt={4} sx={{borderBottom:"2px solid #C9C9C9",pb:2}}>
        <Grid2 size={{md:3}}>
        <Chip label="Non Refundable" sx={{border:"1px dotted #0086EC",backgroundColor:'#F6FBFF',borderRadius:"5px",color:"#0086EC"}} />
        </Grid2>
        <Grid2 size={{md:3}}>
            <Box sx={{display:"flex",alignItems:'center'}}>
            <FormControlLabel control={<Checkbox defaultChecked />} label="Includes Breakfast" />
            <Typography sx={{color:"#F37120"}}>&euro; 40,00</Typography>
            </Box>
            <Typography sx={{fontSize:"10px",color:"#969BA0",ml:3}}>(€ 20,00 x 2 days)</Typography>
      
        </Grid2>
        <Grid2 size={{md:3}}>
        <Typography sx={{color:"#F37120",fontSize:"18px",fontWeight:"medium"}}>&euro;100,00</Typography>
        </Grid2>
        <Grid2 size={{md:3}}>
      <Button sx={{backgroundColor:"#F37120",color:"#fff",width:"116px",height:"45px"}} onClick={()=>navigate('/bookNowFormHotel')} >Book now</Button>
        </Grid2>

    </Grid2>

    </Box>
  )
}

export default HotelViewComponent
