import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Badge,
  Box,
  Grid,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "@emotion/react";
import burgerImg from "../../assets/menuBurgerImg.png";
import MenuListCardsComponent from "./MenuListCardsComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import TextInput from "../../components/Input/TextInput";
import { useSelector } from "react-redux";
import { ArrowBackOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const sampleData = [
    { product: "Beef Steak burger", price: 40, quantity: 2, total: 40 },
    { product: "Zinger Burger", price: 80, quantity: 1, total: 80 },
    { product: "Cheese Burger", price: 15, quantity: 3, total: 15 },
    { product: "Veg Burger", price: 120, quantity: 4, total: 120 },
    { product: "Chicken cheese burger", price: 25, quantity: 5, total: 25 },
  ];
export default function Completebill({ open, handleClose,back }) {
  const theme = useTheme();
  const navigate=useNavigate()
  const {deliveryType}=useSelector((store)=>store?.widgetSettings)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: isSmallScreen || isMediumScreen ? "90%" : "60%", // Set to 90% of the screen width
            maxWidth: "none", // Prevents default Material-UI maxWidth constraints
            height: "auto", // Allows height to adjust dynamically
            minHeight:'500px'
          },
        }}
      >
        <Box >
        <IconButton
            aria-label="close"
            onClick={back}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <ArrowBackOutlined  sx={{color:"#F37120"}}/>
          </IconButton>
          <DialogTitle
            id="recommendation-dialog-title"
            sx={{ color: "#00000", fontWeight: "bold", fontSize: "1.2rem",mb:3,textAlign: "center"  }}
          >
            Checkout
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{mt:2}}>    
        <Grid2 container spacing={2}>
            <Grid2 size={{md:3}}>
                <Typography sx={{fontWeight:"600",fontSize:"20px"}}>Product</Typography>
            </Grid2>
            <Grid2 size={{md:4}}>
                <Typography sx={{fontWeight:"600",fontSize:"20px"}}>Price</Typography>
            </Grid2>
            <Grid2 size={{md:4}}>
                <Typography sx={{fontWeight:"600",fontSize:"20px"}}>Quantity</Typography>
            </Grid2>
            <Grid2 size={{md:1}}>
                <Typography sx={{fontWeight:"600",fontSize:"20px"}}>Total</Typography>
            </Grid2>
        </Grid2>
              {/* Sample Data Rows */}
      {sampleData.map((item, index) => (
        <Grid2 container key={index} sx={{ marginBottom: 2,mt:2 }} spacing={2}>
          <Grid2 size={{md:3}}>
            <Typography sx={{fontWeight:"600"}}>{item.product}</Typography>
          </Grid2>
          <Grid2 size={{md:4}}>
            <Typography  sx={{fontWeight:"600"}}>&euro;{item.price}</Typography>
          </Grid2>
          <Grid2 size={{md:4}}>
            <Typography  sx={{fontWeight:"600"}}>{item.quantity}</Typography>
          </Grid2>
          <Grid2 size={{md:1}}>
            <Typography  sx={{fontWeight:"600"}}>&euro;{item.total}</Typography>
          </Grid2>
        </Grid2>
      ))}
              <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end', // Align items to the right end
      marginTop: '1rem',
      mr:4,
      mt:5
      
    }}
  >
     <Box sx={{ display: 'flex',gap:"3rem",mb:2, alignItems: 'center' }}>
      <Typography sx={{fontSize:"20px",fontWeight:500}}>Delivery cost</Typography>
      <Typography  sx={{fontSize:"20px",fontWeight:500}}>€39.00</Typography>
    </Box>
    <Box sx={{ display: 'flex',gap:"3rem",mb:2, alignItems: 'center' }}>
      <Typography  sx={{fontSize:"20px",fontWeight:500}}>Total</Typography>
      <Typography  sx={{fontSize:"20px",fontWeight:500}}>€39.00</Typography>
    </Box>
    <Box sx={{ display: 'flex', gap: "3rem",mb:2, alignItems: 'center' }}>
      <Typography  sx={{fontSize:"20px",fontWeight:500}}>VAT 21%</Typography>
      <Typography  sx={{fontSize:"20px",fontWeight:500}}>€9.00</Typography>
    </Box>
    <Box sx={{ display: 'flex', gap: "3rem", alignItems: 'center',borderTop:"1px solid black",pt:2 }}>
      <Typography sx={{fontWeight:"bold"}}>Total Including VAT</Typography>
      <Typography sx={{fontWeight:"bold"}}>€48.00</Typography>
    </Box>
    </Box>
    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',mt:2}}>
        <Button variant="contained" sx={{backgroundColor:'#F37120',color:"#fff",width:"404px",p:2}} onClick={()=>{
            handleClose()
            navigate('/webshopSuccess')} }>
          Pay Now &euro; 50
        </Button>    
    </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}