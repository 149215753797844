import React, { useState, useEffect } from "react";
import { MenuItem, Select, Grid, InputLabel, FormControl, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import Grid2 from "@mui/material/Grid2";

const BirthdayComponent = ({style={}}) => {
  const currentYear = dayjs().year();
  const [selectedDay, setSelectedDay] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState(1); // Start with January
  const [selectedYear, setSelectedYear] = useState(currentYear); // Start with the current year

  // Create an array of months
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Create an array of years (from 1900 to the current year)
  const getYearOptions = () => {
    const years = [];
    for (let i = currentYear; i >= 1900; i--) {
      years.push(i);
    }
    return years;
  };

  // Get the number of days in the selected month and year
  const getDaysInMonth = (month, year) => {
    return dayjs(`${year}-${month}`).daysInMonth();
  };

  // State to keep track of the number of days in the month
  const [daysInMonth, setDaysInMonth] = useState(getDaysInMonth(selectedMonth, selectedYear));

  // Update the days in the month whenever selectedMonth or selectedYear changes
  useEffect(() => {
    const days = getDaysInMonth(selectedMonth, selectedYear);
    setDaysInMonth(days);
    
    // Reset the selected day if it's greater than the new number of days
    if (selectedDay > days) {
      setSelectedDay(1); // Reset to the first day if the current day is invalid
    }
  }, [selectedMonth, selectedYear]);

  return (
    <Box sx={{width:style.width}}>
    <Typography
   sx={{mb:2,fontWeight:'bold',fontSize:"15px",color:'#000000'}}
  >
    Birthdate
  </Typography>
    <Grid2 container spacing={2} alignItems="center">
      {/* Day Dropdown */}
      <Grid2 size={{ xs: 4 }}>
        <FormControl fullWidth
         sx={{
            '& .MuiOutlinedInput-root': {
              height: '40px', // Set height
              borderRadius: '8px', // Set border radius
              backgroundColor: '#FDFDFD', // Background color
            
              '& fieldset': {
                borderColor: '#EBEBEB', // Default border color
              },
              '&:hover fieldset': {
                borderColor: '#EBEBEB', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: '#0B5753', // Change border color when focused
              },
              '& input': {
                outline: 'none', // Remove outline
              },
              width:'100%'
            },
    }}
        >
          <Select
            value={selectedDay}
            onChange={(e) => setSelectedDay(e.target.value)}
          >
            {[...Array(daysInMonth).keys()].map((day) => (
              <MenuItem key={day + 1} value={day + 1}>
                {day + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid2>

      {/* Month Dropdown */}
      <Grid2 size={{ xs: 4 }}>
        <FormControl fullWidth 
            sx={{
                '& .MuiOutlinedInput-root': {
                  height: '40px', // Set height
                  borderRadius: '8px', // Set border radius
                  backgroundColor: '#FDFDFD', // Background color
                 
                  '& fieldset': {
                    borderColor: '#EBEBEB', // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#EBEBEB', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#0B5753', // Change border color when focused
                  },
                  '& input': {
                    outline: 'none', // Remove outline
                  },
                  width:'100%'
                },
        }}>
          <Select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            {months.map((month, index) => (
              <MenuItem key={index + 1} value={index + 1}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid2>

      {/* Year Dropdown */}
      <Grid2 size={{ xs: 4 }}>
        <FormControl fullWidth
         sx={{
            '& .MuiOutlinedInput-root': {
              height: '40px', // Set height
              borderRadius: '8px', // Set border radius
              backgroundColor: '#FDFDFD', // Background color
            
              '& fieldset': {
                borderColor: '#EBEBEB', // Default border color
              },
              '&:hover fieldset': {
                borderColor: '#EBEBEB', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: '#0B5753', // Change border color when focused
              },
              '& input': {
                outline: 'none', // Remove outline
              },
              width:'100%'
            },
    }}
        >
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {getYearOptions().map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid2>
    </Grid2>
    </Box>
  );
};

export default BirthdayComponent;

