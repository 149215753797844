import {
  Box,
  Button,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import overviewImg from "../../assets/overviewImg1.png";
import DoneIcon from "@mui/icons-material/Done";
import roomImgMain from "../../assets/hotelmain1.png";
import TextAreaComponent from "../../components/Input/TextAreaComponent";
import BookingCard from "./BookingCard";
import PaymentCard from "./PaymentCard";
const HotelOverview = () => {
  return (
    <Box>
      <Typography
        sx={{ textAlign: "center", fontSize: "32px", fontWeight: "600" }}
      >
        Overview
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 size={{ md: 8 }}>
          <Grid2 container spacing={2}>
            <Grid2 size={{ md: 4 }} sx={{ minHeight: "164px" }}>
              <Box sx={{width:'100%'}}>
                <img style={{width:"100%"}} src={overviewImg} alt="overview-img" />
              </Box>
            </Grid2>
            <Grid2 size={{ md: 4 }}>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography
                  sx={{ fontWeight: "500", fontSize: "20px", color: "#000000" }}
                >
                  Hotel
                </Typography>
                <Typography sx={{ fontSize: "15px", color: "#969BA0" }}>
                  Akersloot
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography
                  sx={{ fontWeight: "500", fontSize: "20px", color: "#000000" }}
                >
                  Room
                </Typography>
                <Typography sx={{ fontSize: "15px", color: "#969BA0" }}>
                  Special Room
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography
                  sx={{ fontWeight: "500", fontSize: "20px", color: "#000000" }}
                >
                  Check In
                </Typography>
                <Typography sx={{ fontSize: "15px", color: "#969BA0" }}>
                  15 Sep 2024
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography
                  sx={{ fontWeight: "500", fontSize: "20px", color: "#000000" }}
                >
                  Check Out
                </Typography>
                <Typography sx={{ fontSize: "15px", color: "#969BA0" }}>
                  16 Sep 2024
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#F37120",
                  mb: 2,
                }}
              >
                Lowest Price On Our Website
              </Typography>
              <Box sx={{ borderBottom: "1px solid #C9C9C9", pb: 2 }}>
                <Grid2 container spacing={2}>
                  <Grid2 size={{ md: 8 }} sx={{ mb: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <DoneIcon sx={{ color: "#F37120" }} />
                      <Typography sx={{ fontSize: "16px", color: "#000000" }}>
                        {" "}
                        Free Cancellation until Hours before arrival
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <DoneIcon sx={{ color: "#F37120" }} />
                      <Typography sx={{ fontSize: "16px", color: "#000000" }}>
                        No Booking Fee
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <DoneIcon sx={{ color: "#F37120" }} />
                      <Typography sx={{ fontSize: "16px", color: "#000000" }}>
                        Pay on Arrival
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2 size={{ md: 4 }} sx={{ mt: 3 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Button
                        sx={{ backgroundColor: "#F37120", color: "#fff" }}
                      >
                        Confirm Booking
                      </Button>
                    </Box>
                  </Grid2>
                </Grid2>
              </Box>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#F37120",
                  mb: 2,
                }}
              >
                Contact Data
              </Typography>
              <Box sx={{ borderBottom: "1px solid #C9C9C9", pb: 2 }}>
                <Grid2 container spacing={2}>
                  <Grid2 size={{ md: 8 }} sx={{ mb: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", color: "#000000" }}>
                        Name
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#969BA0" }}>
                        Martin
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", color: "#000000" }}>
                        Email
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#969BA0" }}>
                        Martin
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", color: "#000000" }}>
                        Phone
                      </Typography>
                      <Typography sx={{ fontSize: "16px", color: "#969BA0" }}>
                        (012) 1234-567
                      </Typography>
                    </Box>
                  </Grid2>
                </Grid2>
              </Box>
            </Grid2>
            <Grid2 container spacing={2}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#F37120",
                  mb: 2,
                }}
              >
                Room Detail
              </Typography>
              <Grid2 size={{ md: 12 }} sx={{ mb: 1 }}>
                <Box sx={{width:"100%"}}>
                  <img style={{width:"80%"}} src={roomImgMain} alt="room-img" />
                </Box>
              </Grid2>
              <Grid2 size={{ md: 10 }} sx={{ mb: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Hotel
                  </Typography>
                  <Typography sx={{ color: "#969BA0", fontSize: "15px" }}>
                    Martin
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Room
                  </Typography>
                  <Typography sx={{ color: "#969BA0", fontSize: "15px" }}>
                    martin@jimani.nl
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Check In Date
                  </Typography>
                  <Typography sx={{ color: "#969BA0", fontSize: "15px" }}>
                    15 Sep 2024
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Persons
                  </Typography>
                  <Typography sx={{ color: "#969BA0", fontSize: "15px" }}>
                    2 Adults
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Check In Name
                  </Typography>
                  <Typography sx={{ color: "#969BA0", fontSize: "15px" }}>
                    Martin
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Check Out Date
                  </Typography>
                  <Typography sx={{ color: "#969BA0", fontSize: "15px" }}>
                    16 Sep 2024
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Check Out Time
                  </Typography>
                  <Typography sx={{ color: "#969BA0", fontSize: "15px" }}>
                    10:00AM
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 size={{ md: 12 }} sx={{ mb: 1 }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#000000",
                    mb: 2,
                    mt: 4,
                  }}
                >
                  Extra Information
                </Typography>

                <Box>
                  <TextAreaComponent
                    label={"DO You have questions or suggestions?"}
                    name={"terms"}
                    setField={() => {}}
                    value={""}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <InputLabel sx={{ mb: 2 }} id="demo-simple-select-label">
                    In Which Language do you prefer to receive your booking
                    information?
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={""}
                      label="lang"
                      onChange={() => {}}
                    >
                      <MenuItem value={10}>English</MenuItem>
                      <MenuItem value={20}>French</MenuItem>
                      <MenuItem value={30}>Germany</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={{ md: 4 }}>
          <BookingCard />
          <PaymentCard />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default HotelOverview;
