import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import Addguest from "./Addguest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import lang from "../../assets/lang.png";
import DateComponent from "./DateComponent";
import ReservationType from "./ReservationType";
import TimeComponent from "./TimeComponent";
import ReservationGroup from "./ReservationGroup";
import { useDispatch, useSelector } from "react-redux";
import {
    setDateLoading,
    setExtraProductData,
    setIsBackToHoreca,
    setIsOpenWidget,
    setLanguageID,
    setLanguageWords,
    setReservationTypeDatas,
    toggleBookNowOpen,
    updateDefaultLanguage,
} from "../../store/slice/Addguest.slice";
import BookNowForm from "./BooknowForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import BGIMG from "../../assets/BG.png";
import {
    FetchWidgetConfigDetails,
    getCalenderDatas,
    getExtraProductDetails,
    getLanguageDetails,
    getReservationType,
    getReservationTypeBasedOnProducts,
    getWidgetSetUpPosition,
    getWidgetSettings,
    getWidgetTitleandInfo,
} from "../../api/HorecaWidget.service";
import { CheckCircleTwoTone } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
    setLanguageLoaded,
    updateBlockedDates,
    updateButtonText,
    updateFloating,
    updateLanguageData,
    updateWidgetConfig,
    updateWidgetSetting,
} from "../../store/slice/widgetSetting.slice";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs?.extend(customParseFormat); // Extend for custom date format parsing

let loadSettings = null;
let widgetIdSettings = null;

const HorecaWidget = ({
    dynamicPosition,
    widgetSetupId,
    buttonPosition,
    widgetPosition,
    floating,
}) => {
    const [widgetLoading, setWidgetLoading] = useState(false);
    const [langLoading, setLangLoading] = useState(false);
    const theme = useTheme();
    const isLandscape = useMediaQuery("(orientation: landscape)");
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const {
        totalCount,
        selectedDate,
        reservType,
        time,
        reservGroup,
        adult,
        child,
        kid,
        baby,
        toddler,
        reservationGroupData,
        dateLoading,
        isBackToHoreca,
        defaultLanguage,
        selectedExtraProduct,
        languageWords,
        languageId,
        isOpenWidget,
    } = useSelector((store) => store.addGuest);
    const totalSum = selectedExtraProduct?.reduce((acc, product) => {
        return acc + product?.quantity * product?.price;
    }, 0);
    const {
        WidgetSetting,
        key,
        widgetConfig,
        languageData,
        buttonText,
        languageLoaded,
    } = useSelector((store) => store?.widgetSettings);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const { isBookNowOpen } = useSelector((store) => store.addGuest);
    const dispatch = useDispatch();
    const [reservTypeLoading, setReserveTypeLoading] = useState(false);
    const [reservationRequestMin, setResReqMin] = useState(0);
    const [isAddGuest, setIsAddGuest] = useState(false);
    const dateRef = useRef(null);
    const guestRef = useRef(null);
    const reservationType = useRef(null);
    const timeRef = useRef(null);
    const reservationGroupRef = useRef(null);
    const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
    const [isReservationType, setIsReservationType] = useState(false);
    const [isTimeComponent, setIsTimeComponent] = useState(false);
    const [isGrouptype, setIsTypeGroup] = useState(false);
    const [openWidget, setOpenWidget] = useState(
        location?.state?.openWidgetScreen ?? isOpenWidget
    );
    const getWidgetPositionStyle = (position, btn) => {
        switch (position) {
            case "Top":
                return { top: "0", left: "0", transform: "translate(0, 0)" };
            case "Top-Left":
                return { top: "20px", left: "0px", transform: "translate(0, 0)" };
            case "Top-Middle":
                return { top: "0", left: "50%", transform: "translateX(-50%)" };
            case "Top-Right":
                return { top: "0", right: "20px", transform: "translate(0, 0)" };
            case "Middle-Left":
                return { top: "50%", left: "0px", transform: "translateY(-50%)" };
            case "Middle-Center":
                return { top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
            case "Middle-Right":
                return { top: "50%", right: "20px", transform: "translateY(-50%)" };
            case "Bottom-Left":
                return { bottom: "20px", left: "0px", transform: "translate(0, 0)" };
            case "Bottom-Middle":
                return { bottom: "20px", left: "50%", transform: "translateX(-50%)" };
            case "Bottom-Right":
                return { bottom: "0", right: "0", transform: "translate(0, 0)" };
            default:
                if (btn) {
                    return { bottom: "0", right: "0", transform: "translate(0, 0)" };
                } else {
                    return {
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    };
                }
        }
    };
    const handleLanguageChange = (event) => {
        const selectedLangCode = event.target.value;
        const selectedLanguage = languageData?.find(
            (item) => item.langcode === selectedLangCode
        );     
        dispatch(setIsBackToHoreca(false));  
        dispatch(updateDefaultLanguage(selectedLanguage?.langcode));
        dispatch(setLanguageID(selectedLanguage?.idlanguage));
        localStorage.setItem(
            'widget',
            JSON.stringify({
              id: widgetSetupId,
              key: key,
              lang:selectedLanguage?.langcode ,
            })
          );
        
    };
    const fetchLanguageDetails = async () => {
        setLangLoading(true);
        try {
            const response = await getLanguageDetails(key);
            if(response?.status != 200){
                console.error("Error fetching language details widget:", response);
                return;
            }
            dispatch(updateLanguageData(response?.data));
            const langParam = queryParams.get("lang");
            if (response?.data) {
                const defaultLang = !defaultLanguage && !langParam
                    ? response?.data?.find((item) => item?.defaultLanguage)?.langcode
                    : langParam ? langParam :  defaultLanguage;
                  
                 dispatch(updateDefaultLanguage(defaultLang));
                dispatch(setLanguageLoaded(true)); // Set the flag to true
                dispatch(setLanguageID(defaultLang?.idlanguage));
            }
            setLangLoading(false);
        } catch (error) {
            console.error("Error fetching language details widget:", error?.message);
            setLangLoading(false);
        }
    };
    const fetchWidgetSettings = async (language) => {
        try {
            const response = await getWidgetSettings(language, key);
            if(response?.status !== 200 || !response?.data){
                throw new Error("Failed to fetch widget settings. Please try again later.");
            }
            loadSettings = response?.data;
            dispatch(updateWidgetSetting(response?.data));
        } catch (error) {
            const errorMsg = error?.message || "An unexpected error occurred while fetching widget settings.";
            console.error("Error fetching widget settings:", errorMsg);
        }
    };
    const fetchWidgetTitleandInfo = async (widgetSetupId, language) => {
        try {
            const response = await getWidgetTitleandInfo(widgetSetupId, language);
            if(response?.status != 200 || !response?.data) {
                throw new Error("Failed to fetch widget title and info. Please try again later.");
            }
            widgetIdSettings = response?.data;
        } catch (error) {
            console.error("Error fetching widget settings:", error?.message);
        }
    };

    const getUnavailableCalenderDetails = async () => {
        dispatch(setDateLoading(true));
        try {
            const response = await getCalenderDatas(
                key,
                totalCount,
                widgetSetupId,
                reservationRequestMin
            );
            if(response?.status!= 200 ||!response?.data) {
                throw new Error("Failed to fetch unavailable dates. Please try again later.");
            }
            dispatch(updateBlockedDates(response?.data));
            dispatch(setDateLoading(false));
            return response;
        } catch (error) {
            console.error("Error fetching unavailable dates", error);
            dispatch(setDateLoading(false));
        }
    };

    const fetchReservationType = async () => {
        setReserveTypeLoading(true); // Start loading
        const parsedDate = dayjs(selectedDate, "DD-MM-YYYY", true);
        // Check if date is valid
        if (!parsedDate.isValid()) {
            setReserveTypeLoading(false); // Stop loading on error
            return;
        }
        // Format the valid date into 'YYYY-MM-DD'
        const formattedDate = parsedDate.format("YYYY-MM-DD");
        const payload = {
            idwidgetsetup: parseInt(widgetSetupId),
            amount: parseInt(totalCount),
            date: formattedDate,
            key: key,
            field2: false,
            field1GuestCount: parseInt(adult),
            field2GuestCount: parseInt(child),
            field3GuestCount: parseInt(kid),
            field4GuestCount: parseInt(baby),
            field5GuestCount: parseInt(toddler),
            reservationRequestMin: parseInt(reservationRequestMin),
            lang: defaultLanguage,
        };
        try {
            const response = await getReservationType(payload);
            if (response.status !=200 || !response?.data || response.length === 0) {
                throw new Error("Failed to fetch reservation types. Please try again later.");   
            }
            // Iterate over the response data
            const promises = response?.data?.map(async (item) => {
                const prodId = item?.idproducthoreca; // Assuming 'idproducthoreca' is the identifier
                // Initialize totalCost and vat to default values
                let totalCost = 0;
                let vat = null;
                if (prodId) {
                    // Get cost data for the product using prodId
                    const productData = await getReservationTypeBasedOnProducts(
                        key,
                        prodId
                    );
                    // Calculate the total cost for this item using the guest counts
                    totalCost =
                        (productData?.price1 || 0) * parseInt(adult) +
                        (productData?.price2 || 0) * parseInt(child) +
                        (productData?.price3 || 0) * parseInt(kid) +
                        (productData?.price4 || 0) * parseInt(baby) +
                        (productData?.price5 || 0) * parseInt(toddler);
                    vat = productData?.vat;
                }
                // Return the item with the total cost added
                return {
                    ...item, // Copy the original item fields
                    totalCost, // Add the calculated total cost
                    vat,
                };
            });
            // Wait for all promises to complete
            const updatedResponse = await Promise.all(promises);
            // Dispatch the updated response with the total costs
            dispatch(setReservationTypeDatas(updatedResponse));
        } catch (error) {
            console.error("Error fetching reservation type:", error?.message);
        } finally {
            setReserveTypeLoading(false); // Stop loading after success or error
        }
    };

    const fetchWidgetSettingsDetails = async (lang) => {
        try {
            setWidgetLoading(true);
            const response = await FetchWidgetConfigDetails(key, widgetSetupId, lang);
            if(response?.status!= 200 ||!response?.data){
                throw new Error("Failed to fetch widget settings details. Please try again later.");
            }
            const result = response?.data?.basicLanguages?.reduce((acc, item) => {
                const tag = item.tag.replace(/{|}/g, "").replace(/\s+/g, "");
                const data = item.widgetTranslateLanguages[0]?.name;
                acc[tag] = data; // Assign data to the key acc[tag]
                return acc;
            }, {});
            dispatch(updateWidgetConfig(response?.data));
            dispatch(updateButtonText(response?.data?.fields?.reservationButtonText));
            dispatch(setLanguageWords(result));
            setWidgetLoading(false);
        } catch (error) {
            console.error("Error fetching widget settings details:", error);
            setWidgetLoading(false);
        }
    };
    const handleClose = () => {
        setOpenWidget(false);
      };



    useEffect(() => {
        if (!languageLoaded && !isBackToHoreca) {
            
            fetchLanguageDetails();
        }
    }, [languageLoaded, isBackToHoreca]);

    useEffect(() => {
        if (defaultLanguage && defaultLanguage.trim() !== "" && !isBackToHoreca) {
            fetchWidgetSettings(defaultLanguage);
        }
    }, [defaultLanguage, isBackToHoreca]);


    useEffect(() => {
        if (defaultLanguage && defaultLanguage.trim() !== "") {
            // Checks if defaultLanguage is a non-empty string
            fetchWidgetSettingsDetails(defaultLanguage);
        }
    }, [defaultLanguage]);

    useEffect(() => {
        if (defaultLanguage?.trim() != "" && !isBackToHoreca)
            fetchWidgetTitleandInfo(widgetSetupId, defaultLanguage);
    }, [defaultLanguage, isBackToHoreca]);

    useEffect(() => {
        if (
            WidgetSetting?.length > 0 &&
            totalCount > 0 &&
            !isAddGuest &&
            !isBackToHoreca
        ) {
            getUnavailableCalenderDetails();
        }
    }, [WidgetSetting, totalCount, isAddGuest, isBackToHoreca]);

    useEffect(() => {

        if (!isBackToHoreca) {
            fetchReservationType();
        }
    }, [
        totalCount,
        adult,
        child,
        kid,
        toddler,
        defaultLanguage,
        selectedDate,
        isBackToHoreca,
    ]);
    useEffect(() => {
        document.body.setAttribute('style', 'background-color: transparant;'); 
        const resReqMin = WidgetSetting?.find(
            (item) => item?.tag == "reservationRequest"
        )?.data;
        setResReqMin(resReqMin == null ? "22" : resReqMin);
    }, [WidgetSetting]);
 
    const send = (
        frameHeight,
        frameWidth,
        top,
        right,
        bottom,
        left,
        floating,
        redirectUrl
    ) => {
        window.parent.postMessage(
            {
                reservationIframeHeight: frameHeight,
                reservationIframeWidth: frameWidth,
                reservationIframePositionTop: top,
                reservationIframePositionRight: right,
                reservationIframePositionBottom: bottom,
                reservationIframePositionLeft: left,
                reservationIframePositionFloating: floating,
                redirectUrl: redirectUrl,
            },
            "*"
        );
    };
    // get the height of the widget
    const getHeight = () => {
         setTimeout(() => {
            let rootHeight = document.getElementsByClassName("MuiBox-root")[1];
            let popupHeight = document.getElementsByClassName("MuiPaper-root")[0];
             let frameHeight = 200;


             console.log("update height");

            if (rootHeight) {

                if (rootHeight.clientHeight > frameHeight) {

                    if (rootHeight.clientWidth < 700) {
                        frameHeight = rootHeight.clientHeight + 100;
                    }
                    else {
                        frameHeight = rootHeight.clientHeight + 20;
                    }
                }
                if (popupHeight) {

                    if (frameHeight < 520) {
                        frameHeight = 520;
                    }
                 }
                

            }
            send(
                frameHeight + "px",
                "100%",
                "",
                "0px",
                "0px",
                "",
                widgetConfig?.settings?.floatButtonActive
            )


        }, 100);

    };
    // get the height of the MuiBox-root after loading the full page
    useEffect(() => {
       if (floating === "relative") {
            getHeight();
        }
    });

    if(widgetLoading){
        return (
          <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={widgetLoading}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        )
      }

    if (floating === "relative") {
        return (
            (
                <Box
                    className="test"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: isSmallScreen ? "flex-start" : "flex-end",
                        position: "absolute",
                        width: "100%",
                        //height: isSmallScreen || isMediumScreen ? "100vh" : "auto",
                        //...getWidgetPositionStyle(widgetPosition, false),
                        backgroundColor: theme.widget?.mainBg,
                        zIndex: 1000,

                    }}


                >
                    <Box
                        sx={{
                            width: "100%",
                            maxWidth: "1567px",
                            padding: { xs: 2, sm: 3 },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                mb: 2,
                            }}
                        >
                            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                                <FormControl
                                    variant="outlined"
                                    sx={{
                                        minWidth: 120,
                                        backgroundColor: theme?.widget?.langBgColor,
                                        width: { xs: "100px", md: "120px" },
                                        height: "45px",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <Select
                                        value={defaultLanguage || ""}
                                        onChange={handleLanguageChange} // Set the onChange handler here
                                        IconComponent={() => (
                                            <KeyboardArrowDownIcon sx={{ color: "black", mr: 1 }} />
                                        )}
                                        displayEmpty
                                        renderValue={() => (
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <img
                                                    src={lang}
                                                    alt={"lang-img"}
                                                    style={{ width: "40px", marginRight: "10px" }}
                                                />
                                            </Box>
                                        )}
                                        sx={{
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "transparent",
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "transparent",
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "transparent",
                                            },
                                            "& .MuiOutlinedInput-input": {
                                                padding: 1,
                                                //     color: "#9F9F9F",
                                                fontSize: "12px",
                                            },
                                        }}
                                    >
                                        {languageData?.map((item, index) => (
                                            <MenuItem
                                                key={item?.idlanguage}
                                                value={item?.langcode}
                                                sx={{
                                                    color: theme?.palette?.widget?.langTextColor, // Text color of MenuItem
                                                    "&.Mui-selected": {
                                                        backgroundColor: theme?.widget?.langActiveBgColor, // Highlight selected item
                                                    },
                                                    "&:hover": {
                                                        backgroundColor: theme?.widget?.langBgHover, // Hover effect for MenuItem
                                                        color: theme?.widget?.langTextHover,
                                                        "&.Mui-selected": {
                                                            backgroundColor: theme?.widget?.langBgHover, // Hover effect for MenuItem
                                                            color: theme?.widget?.langTextHover,
                                                        },
                                                    },
                                                }}
                                            >
                                                {(item?.langcode == defaultLanguage?.trim()) != "" ? (
                                                    <>
                                                        {item?.language}
                                                        <CheckBoxIcon
                                                            sx={{
                                                                color: theme?.widget?.langCheckboxActive,
                                                                marginRight: "8px",
                                                                ml: 2,
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    item?.language
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {/* <IconButton aria-label="closeIcon" size="large" onClick={()=> setOpenWidget(!openWidget)} 
  sx={{backgroundColor:theme?.widgetButton?.closeBg,
 
    '&:hover':{
      backgroundColor:theme?.widgetButton?.closeHover,
      
    }
  }}>
<CloseIcon   sx={{
color:theme?.widgetButton?.closeText,
'&:hover':{

color:theme?.widgetButton?.textcloseTextHover,
}
}}/>
</IconButton> */}
                            </Box>
                        </Box>

                        <Grid container spacing={2} sx={{ mb: 3 }}>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <SelectComponent
                                    label={languageWords?.Guest}
                                    value={totalCount}
                                    onClick={() => {
                                        setIsAddGuest(!isAddGuest);
                                    }}
                                    ref={guestRef}
                                />
                                <Addguest
                                    isOpen={isAddGuest}
                                    handleClose={() => setIsAddGuest(false)}
                                    Ref={guestRef.current}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <SelectComponent
                                    label={languageWords?.date}
                                    value={
                                        selectedDate?.trim() != "" ? selectedDate : "DD-MM-YYYY"
                                    }
                                    onClick={() => setIsDateDropdownOpen(!isDateDropdownOpen)}
                                    ref={dateRef}
                                    disabled={totalCount > 0 ? false : true}
                                />
                                <DateComponent
                                    isOpen={isDateDropdownOpen}
                                    handleClose={() => setIsDateDropdownOpen(false)}
                                    Ref={dateRef.current}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <SelectComponent
                                    label={languageWords?.reserveType}
                                    value={
                                        reservType &&
                                            typeof reservType === "object" &&
                                            Object.keys(reservType).length > 0
                                            ? reservType?.name
                                            : "Type"
                                    }
                                    onClick={() => setIsReservationType(!isReservationType)}
                                    ref={reservationType}
                                    disabled={selectedDate?.trim() != "" ? false : true}
                                />
                                <ReservationType
                                    isOpen={isReservationType}
                                    handleClose={() => setIsReservationType(false)}
                                    Ref={reservationType.current}
                                    isLoading={reservTypeLoading}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <SelectComponent
                                    label={languageWords?.Time}
                                    value={time?.trim() != "" ? time?.substring(0, 5) : "HH:MM"}
                                    onClick={() => setIsTimeComponent(!isTimeComponent)}
                                    ref={timeRef}
                                    disabled={
                                        reservType &&
                                            typeof reservType === "object" &&
                                            Object.keys(reservType).length > 0
                                            ? false
                                            : true
                                    }
                                />
                                <TimeComponent
                                    isOpen={isTimeComponent}
                                    handleClose={() => setIsTimeComponent(false)}
                                    Ref={timeRef.current}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <SelectComponent
                                    label={languageWords?.reserveGroup}
                                    value={
                                        reservGroup &&
                                            typeof reservGroup === "object" &&
                                            Object.keys(reservGroup).length > 0
                                            ? reservGroup?.name
                                            : "Group"
                                    }
                                    onClick={() => setIsTypeGroup(!isGrouptype)}
                                    ref={reservationGroupRef}
                                    disabled={time?.trim() != "" ? false : true}
                                />
                                <ReservationGroup
                                    isOpen={isGrouptype}
                                    handleClose={() => setIsTypeGroup(false)}
                                    Ref={reservationGroupRef.current}
                                    disabled={
                                        reservGroup &&
                                            typeof reservGroup === "object" &&
                                            Object.keys(reservGroup).length > 0
                                            ? false
                                            : true
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        height: "70px",
                                        backgroundColor:
                                            reservType &&
                                                typeof reservType === "object" &&
                                                Object.keys(reservType).length > 0 &&
                                                totalCount > 0 &&
                                                selectedDate?.trim() != "" &&
                                                time?.trim() != ""
                                                ? theme?.widget?.bookNowbtnBgActive
                                                : theme?.widget?.bookNowBtnBg,
                                        color:
                                            reservType &&
                                                typeof reservType === "object" &&
                                                Object.keys(reservType).length > 0 &&
                                                totalCount > 0 &&
                                                selectedDate?.trim() != "" &&
                                                time?.trim() != ""
                                                ? theme?.widget?.booknowBtnTextColorActive
                                                : theme?.widget?.booknowBtnTextColor,
                                        fontWeight: "bold",
                                        display: "flex", // Ensure flexbox is used
                                        flexDirection: "column", // Align content in a column (Book Now on top, €25,00 below)
                                        justifyContent: "center", // Center the text vertically
                                        alignItems: "center", // Center the text horizontally
                                    }}
                                    onClick={() => {
                                       
                                        if (
                                            reservType &&
                                            typeof reservType === "object" &&
                                            Object.keys(reservType).length > 0 &&
                                            totalCount > 0 &&
                                            selectedDate?.trim() != "" &&
                                            time?.trim() != ""
                                        ) {
                                            navigate(`/bookNow`);
                                        } else {
                                         
                                            return;
                                        }
                                    }}
                                >
                                    {languageWords?.Booknow}
                                    {reservType?.totalCost > 0 && (
                                        <Box component="span" fontSize="13px" fontWeight="200">
                                            €{reservType?.totalCost}
                                        </Box>
                                    )}
                                </Button>
                            </Grid>
                        </Grid>

                        {isBookNowOpen && <BookNowForm />}
                    </Box>
                </Box>
            )
        );



    }
    // Widget
    return (
        send(
            "100px",
            "150px",
            "",
            "0px",
            "0px",
            "",
            widgetConfig?.settings?.floatButtonActive
        ),
        (
            <Box
                sx={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "",
                    position: "relative",
                }}
            >
                {buttonText?.trim() != "" && !openWidget && (
                    <Button
                        sx={{
                            position: "absolute",
                            ...getWidgetPositionStyle(
                                widgetConfig?.settings?.positionButton,
                                true
                            ),
                            backgroundColor: theme?.widgetButton?.bgColor,
                            color: theme?.widgetButton?.textColor,
                            margin: "10px",
                            zIndex: "99999",
                        }}
                        onClick={() => {
                            setOpenWidget(!openWidget);
                            dispatch(setIsOpenWidget(!isOpenWidget));
                        }}
                    >
                        {buttonText}
                    </Button>
                )}

                {openWidget &&
                    (send("100vh", "100%", "0px", "0px", "0px", "", floating),
                        (
                            <Box
                                className="test"
                               
                                sx={{
                                     display: "flex",
                                     justifyContent: "center",
                                     alignItems: isSmallScreen ? "flex-start" : "flex-end",
                                     position: "absolute",
                                     width: "100%",
                                     bottom: "0px",
                                  //   paddingBottom: "20px",
                                     backgroundColor: theme.widget?.mainBg,
                                   
                                     height:isSmallScreen || isMediumScreen ? "100%" : "auto",
                                     zIndex: 1000,
                                }}
                            >
                                <Box
                                    sx={{

                                        width: "100%",
                                        maxWidth: "1567px",
                                        padding: { xs: 2, sm: 3 },
                                        height: "100%" ,
                                      
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            mb: 2,
                                        }}
                                    >
                                        <Box
                                            sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
                                        >
                                            <FormControl
                                                variant="outlined"
                                                sx={{
                                                    minWidth: 120,
                                                    backgroundColor: theme?.widget?.langBgColor,
                                                    width: { xs: "100px", md: "120px" },
                                                    height: "45px",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <Select
                                                    value={defaultLanguage}
                                                    onChange={handleLanguageChange} // Set the onChange handler here
                                                    // IconComponent={() => (
                                                    //     <KeyboardArrowDownIcon
                                                    //         sx={{ color: "black", mr: 1 }}
                                                    //     />
                                                    // )}
                                                    displayEmpty
                                                    renderValue={() => (
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <img
                                                                src={lang}
                                                                alt={"lang-img"}
                                                                style={{ width: "40px", marginRight: "10px" }}
                                                            />
                                                        </Box>
                                                    )}
                                                    sx={{
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "transparent",
                                                        },
                                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "transparent",
                                                        },
                                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "transparent",
                                                        },
                                                        "& .MuiOutlinedInput-input": {
                                                            padding: 1,
                                                            //     color: "#9F9F9F",
                                                            fontSize: "12px",
                                                        },
                                                    }}
                                                >
                                                    {languageData?.map((item, index) => (
                                                        <MenuItem
                                                            key={item?.idlanguage}
                                                            value={item?.langcode}
                                                            sx={{
                                                                color: theme?.palette?.widget?.langTextColor, // Text color of MenuItem
                                                                "&.Mui-selected": {
                                                                    backgroundColor:
                                                                        theme?.widget?.langActiveBgColor, // Highlight selected item
                                                                },
                                                                "&:hover": {
                                                                    backgroundColor: theme?.widget?.langBgHover, // Hover effect for MenuItem
                                                                    color: theme?.widget?.langTextHover,
                                                                    "&.Mui-selected": {
                                                                        backgroundColor: theme?.widget?.langBgHover, // Hover effect for MenuItem
                                                                        color: theme?.widget?.langTextHover,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {(item?.langcode == defaultLanguage?.trim()) !=
                                                                "" ? (
                                                                <>
                                                                    {item?.language}
                                                                    <CheckBoxIcon
                                                                        sx={{
                                                                            color: theme?.widget?.langCheckboxActive,
                                                                            marginRight: "8px",
                                                                            ml: 2,
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                item?.language
                                                            )}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <IconButton
                                                aria-label="closeIcon"
                                                size="large"
                                                onClick={() => setOpenWidget(!openWidget)}
                                                sx={{
                                                    backgroundColor: theme?.widgetButton?.closeBg,

                                                    "&:hover": {
                                                        backgroundColor: theme?.widgetButton?.closeHover,
                                                    },
                                                }}
                                            >
                                                <CloseIcon
                                                    sx={{
                                                        color: theme?.widgetButton?.closeText,
                                                        "&:hover": {
                                                            color: theme?.widgetButton?.textcloseTextHover,
                                                        },
                                                    }}
                                                />
                                            </IconButton>
                                        </Box>
                                    </Box>

                                    <Grid container spacing={2} sx={{ mb: 3 }}>
                                        <Grid item xs={12} sm={6} md={4} lg={2}>
                                            <SelectComponent
                                                label={languageWords?.Guest}
                                                value={totalCount}
                                                onClick={() => {
                                                    setIsAddGuest(!isAddGuest);
                                                }}
                                                ref={guestRef}
                                            />
                                            <Addguest
                                                isOpen={isAddGuest}
                                                handleClose={() => setIsAddGuest(false)}
                                                Ref={guestRef.current}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4} lg={2}>
                                            <SelectComponent
                                                label={languageWords?.date}
                                                value={
                                                    selectedDate?.trim() != "" ? selectedDate : "DD-MM-YYYY"
                                                }
                                                onClick={() => setIsDateDropdownOpen(!isDateDropdownOpen)}
                                                ref={dateRef}
                                                disabled={totalCount > 0 ? false : true}
                                            />
                                            <DateComponent
                                                isOpen={isDateDropdownOpen}
                                                handleClose={() => setIsDateDropdownOpen(false)}
                                                Ref={dateRef.current}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4} lg={2}>
                                            <SelectComponent
                                                label={languageWords?.reserveType}
                                                value={
                                                    reservType &&
                                                        typeof reservType === "object" &&
                                                        Object.keys(reservType).length > 0
                                                        ? reservType?.name
                                                        : "Type"
                                                }
                                                onClick={() => setIsReservationType(!isReservationType)}
                                                ref={reservationType}
                                                disabled={selectedDate?.trim() != "" ? false : true}
                                            />
                                            <ReservationType
                                                isOpen={isReservationType}
                                                handleClose={() => setIsReservationType(false)}
                                                Ref={reservationType.current}
                                                isLoading={reservTypeLoading}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4} lg={2}>
                                            <SelectComponent
                                                label={languageWords?.Time}
                                                value={
                                                    time?.trim() != "" ? time?.substring(0, 5) : "HH:MM"
                                                }
                                                onClick={() => setIsTimeComponent(!isTimeComponent)}
                                                ref={timeRef}
                                                disabled={
                                                    reservType &&
                                                        typeof reservType === "object" &&
                                                        Object.keys(reservType).length > 0
                                                        ? false
                                                        : true
                                                }
                                            />
                                            <TimeComponent
                                                isOpen={isTimeComponent}
                                                handleClose={() => setIsTimeComponent(false)}
                                                Ref={timeRef.current}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4} lg={2}>
                                            <SelectComponent
                                                label={languageWords?.reserveGroup}
                                                value={
                                                    reservGroup &&
                                                        typeof reservGroup === "object" &&
                                                        Object.keys(reservGroup).length > 0
                                                        ? reservGroup?.name
                                                        : "Group"
                                                }
                                                onClick={() => setIsTypeGroup(!isGrouptype)}
                                                ref={reservationGroupRef}
                                                disabled={time?.trim() != "" ? false : true}
                                            />
                                            <ReservationGroup
                                                isOpen={isGrouptype}
                                                handleClose={() => setIsTypeGroup(false)}
                                                Ref={reservationGroupRef.current}
                                                disabled={
                                                    reservGroup &&
                                                        typeof reservGroup === "object" &&
                                                        Object.keys(reservGroup).length > 0
                                                        ? false
                                                        : true
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4} lg={2}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{
                                                    height: "70px",
                                                    backgroundColor:
                                                        reservType &&
                                                            typeof reservType === "object" &&
                                                            Object.keys(reservType).length > 0 &&
                                                            totalCount > 0 &&
                                                            selectedDate?.trim() != "" &&
                                                            time?.trim() != ""
                                                            ? theme?.widget?.bookNowbtnBgActive
                                                            : theme?.widget?.bookNowBtnBg,
                                                    color:
                                                        reservType &&
                                                            typeof reservType === "object" &&
                                                            Object.keys(reservType).length > 0 &&
                                                            totalCount > 0 &&
                                                            selectedDate?.trim() != "" &&
                                                            time?.trim() != ""
                                                            ? theme?.widget?.booknowBtnTextColorActive
                                                            : theme?.widget?.booknowBtnTextColor,
                                                    fontWeight: "bold",
                                                    display: "flex", // Ensure flexbox is used
                                                    flexDirection: "column", // Align content in a column (Book Now on top, €25,00 below)
                                                    justifyContent: "center", // Center the text vertically
                                                    alignItems: "center", // Center the text horizontally
                                                }}
                                                onClick={() => {
                                                   
                                                    if (
                                                        reservType &&
                                                        typeof reservType === "object" &&
                                                        Object.keys(reservType).length > 0 &&
                                                        totalCount > 0 &&
                                                        selectedDate?.trim() != "" &&
                                                        time?.trim() != ""
                                                    ) {
                                                       
                                                        navigate(`/bookNow`);
                                                    } else {
                                                        return;
                                                    }
                                                }}
                                            >
                                                {languageWords?.Booknow}
                                                {reservType?.totalCost > 0 && (
                                                    <Box component="span" fontSize="13px" fontWeight="200">
                                                        €{reservType?.totalCost}
                                                    </Box>
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    {isBookNowOpen && <BookNowForm />}
                                </Box>
                            </Box>
                        ))}
            </Box>
        )
    );
};

export default HorecaWidget;
