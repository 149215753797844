import {
  Box,
  Button,
  Grid2,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import LanguageMenuItem from "../../components/SelectComponent/LanguageMenuItem";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@emotion/react";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuListCardsComponent from "./MenuListCardsComponent";
import { useSelector } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import footerImg from "../../assets/footerLogo.png";
const MenuItemListComponent = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {webShopMenuPosition,shopType}=useSelector((store)=>store?.widgetSettings)
  const MenuItemData=[
    {
        id:1,
        name:'Beef Steak Burger',
        price:"39.00",
        info:"Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
        type:"Beef",
        cookingType:["Grilled","Grilled"],
        eatingType:["Crsipy","Crsipy","Crispy"],
    },
    {
        id:2,
        name:'Beef Steak Burger',
        price:"39.00",
        info:"Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
        type:"Beef",
        cookingType:["Grilled","Grilled"],
        eatingType:["Crsipy","Crsipy","Crispy"],
    },
    {
        id:3,
        name:'Beef Steak Burger',
        price:"39.00",
        info:"Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
        type:"Beef",
        cookingType:["Grilled","Grilled"],
        eatingType:["Crsipy","Crsipy","Crispy"],
    },
    {
        id:4,
        name:'Beef Steak Burger',
        price:"39.00",
        info:"Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
        type:"Beef",
        cookingType:["Grilled","Grilled"],
        eatingType:["Crsipy","Crsipy","Crispy"],
    },
    {
        id:5,
        name:'Beef Steak Burger',
        price:"39.00",
        info:"Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
        type:"Beef",
        cookingType:["Grilled","Grilled"],
        eatingType:["Crsipy","Crsipy","Crispy"],
    },
    {
        id:6,
        name:'Beef Steak Burger',
        price:"39.00",
        info:"Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
        type:"Beef",
        cookingType:["Grilled","Grilled"],
        eatingType:["Crsipy","Crsipy","Crispy"],
    },
    {
        id:8,
        name:'Beef Steak Burger',
        price:"39.00",
        info:"Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
        type:"Beef",
        cookingType:["Grilled","Grilled"],
        eatingType:["Crsipy","Crsipy","Crispy"],
    },
    {
        id:9,
        name:'Beef Steak Burger',
        price:"39.00",
        info:"Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
        type:"Beef",
        cookingType:["Grilled","Grilled"],
        eatingType:["Crsipy","Crsipy","Crispy"],
    }
  ]
  return (
    <Box sx={{ width: "100%",px:2,minHeight:"100vh",height:"auto",backgroundColor:"lightgrey" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          pt: 2,
        }}
      >
         <Box>
          {isSmallScreen ? 
          (
            <Box sx={{display:"flex",alignItems:'center',gap:"0.6rem"}}>
           { !webShopMenuPosition && <ArrowBackIcon />}
            <Typography
              sx={{ fontSize: "1.5rem", fontWeight: "600", color: "#00000" }}
            >
              Burgers
            </Typography>
            </Box>
          ) :!webShopMenuPosition ? <ArrowBackIcon /> : webShopMenuPosition != 'horizontal'  && shopType != 'kiosk' && (
            
            <LanguageMenuItem />
          )}
        </Box>
        <Box >
          {isSmallScreen ? (
            <LanguageMenuItem />
          ) : shopType == 'kiosk'  ?  <LanguageMenuItem /> :  (
            <TextField
              variant="outlined"
              placeholder="Search here..."
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 4,
                  border: "none",
                  "& fieldset": {
                    border: "lightgrey", // Remove border
                  },
                  "&:hover fieldset": {
                    border: "lightgrey", // Prevent border on hover
                  },
                  "&.Mui-focused fieldset": {
                    border: "lightgrey", // Prevent border when focused
                  },
                },
              }}
            />
          )}
        </Box>
      </Box>
      <Box sx={{mt:2}}>
     {!isSmallScreen  && <Typography
              sx={{ fontSize: "1.5rem", fontWeight: "600", color: "#00000" }}
            >
              Burgers
            </Typography>}
            <Typography
              sx={{ fontSize: isSmallScreen ? "10px" : "16px",maxWidth:"970px", color: "#969BA0"}}
            >
              Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. Molestie mattis adipiscing sit condimentum. Pellentesque donec suscipit amet rhoncus eget ac purus. Nec diam mauris et aliquet sit commodo sagittis.
            </Typography>

      </Box>
      <Box>
        <MenuListCardsComponent data={MenuItemData}/>
      </Box>
      <Grid2 container mt={4} sx={{width:"100%",pb:2}}> 
      {!webShopMenuPosition && <Grid2 size={{md:1}}>
        <Box
              sx={{
             //   flex: "0 1 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                src={footerImg}
                alt="footerLogo"
                style={{ minWidth: "76px", height: "auto" }}
              />
            </Box>
        </Grid2>}
        <Grid2 size={{md:8}}>
            <Typography sx={{color:"#969BA0",textWrap:"wrap"}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ipsum eget nisi placerat viverra. Donec non ipsum vel nisi fermentum consectetur. Nulla facilisi. Donec id velit vel eros dictum tempor.
            </Typography>
        </Grid2>
        <Grid2 size={{md:3}}>
            <Box sx={{display:'flex',justifyContent:"flex-end"}}>
            <Button size='large' sx={{backgroundColor:"#F37120",color:"#fff"}} variant="contained" endIcon={<ShoppingCartIcon sx={{color:"#fff"}} />}>
  View Cart
</Button>

            </Box>

        </Grid2>


    </Grid2>

    </Box>
  );
};

export default MenuItemListComponent;
