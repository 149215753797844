// import { Box, InputLabel, TextField, useTheme } from '@mui/material';
// import React, { useState, useCallback, useEffect } from 'react';
// import { debounce } from 'lodash';
// import { useDispatch, useSelector } from 'react-redux';

// const TextAreaComponent = ({ label = '', name, style = {}, placeText = '', setField, required ,isFeedback=false}) => {
//   console.log("namezz",name)
//   const dispatch=useDispatch()
//   const { addFields } = useSelector((store) => store?.feedback);

//   const theme=useTheme()
//   const [value, setValue] = useState('');

//   // Debounced handleChange function
//   const debouncedSetField = useCallback(
//     debounce((name, newValue) => {
//       setField(name, newValue);
//     }, 300), // Adjust debounce time as needed
//     []
//   );

//   // const handleChange = (e) => {
//   //   const newValue = e.target.value;
//   //   setValue(newValue);
//   //   debouncedSetField(name, newValue);
//   // };

//   useEffect(() => {
//     console.log("addD",addFields)
//     const existingField = addFields.find((field) => field.name === name);
//     console.log("exisiting fields",existingField)
//     if (existingField?.data) {
//       setValue(existingField?.data);
//     }
//   }, [addFields, name]);

//   const handleChange = (e) => {
//     if(isFeedback){
//       const newValue = e.target.value;
//       setValue(newValue);
  
//       const updatedFields = addFields.map((field) => {
//         if (field.name === name) {
//           return { ...field, data:newValue};
//         }
//         return field;
//       });
  
//       if (!addFields.some((field) => field.name === name)) {
//         updatedFields.push({
//          data: newValue,
//         });
//       }
//       dispatch(setField(updatedFields));

//     }
//     else{
//     const newValue = e.target.value;
//     setValue(newValue);
//     debouncedSetField(name, newValue);

//     }

//   };

//   return (
//     <Box>
//       {label?.trim() !='' && <InputLabel sx={{ mt: 2, mb: 1, fontSize: "10px", color: theme?.details?.formLabelColor }}>
//         {label}
//       </InputLabel>}
//       <TextField
//         name={name}
//         variant="outlined"
//         value={value}
//         fullWidth
//         placeholder={placeText}
//         sx={{
//           '& .MuiOutlinedInput-root': {
//             borderRadius: '8px',
//             backgroundColor: '#FDFDFD',
//             '& fieldset': {
//               borderColor: '#EBEBEB',
//             },
//             '&:hover fieldset': {
//               borderColor: '#EBEBEB',
//             },
//             '&.Mui-focused fieldset': {
//               borderColor: '#0B5753',
//             },
//             '& input': {
//               outline: 'none',
//             },
//             width: style.width || '100%',
//             height: style.height || '150px',
//           },
//         }}
//         multiline
//         rows={4}
//         onChange={handleChange}
//       />
//     </Box>
//   );
// };

// export default TextAreaComponent;

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, InputLabel, TextField, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

const TextAreaComponent = ({ label = '', name, style = {}, placeText = '', setField, required, isFeedback = false }) => {
  const dispatch = useDispatch();
  const { addFields } = useSelector((store) => store?.feedback);

  const theme = useTheme();
  const [value, setValue] = useState('');

  // Debounced Redux dispatch
  const debouncedDispatch = useCallback(
    debounce((updatedFields) => {
      dispatch(setField(updatedFields));
    }, 300),
    [dispatch, setField]
  );

  // Debounced setField for non-feedback logic
  const debouncedSetField = useCallback(
    debounce((name, newValue) => {
      setField(name, newValue);
    }, 300),
    [setField]
  );

  useEffect(() => {
    const existingField = addFields.find((field) => field.name === name);
    if (existingField?.data) {
      setValue(existingField?.data);
    }
  }, [addFields, name]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (isFeedback) {
      const updatedFields = addFields.some((field) => field.name === name)
        ? addFields.map((field) =>
            field.name === name ? { ...field, data: newValue } : field
          )
        : [...addFields, { name, data: newValue }];

      // Use debounced dispatch
      debouncedDispatch(updatedFields);
    } else {
      // Use debounced setField
      debouncedSetField(name, newValue);
    }
  };

  return (
    <Box>
      {label?.trim() && (
        <InputLabel
          sx={{
            mt: 2,
            mb: 1,
            fontSize: '14px',
            color: theme?.details?.formLabelColor,
          }}
        >
          {label}
        </InputLabel>
      )}
      <TextField
        name={name}
        variant="outlined"
        value={value}
        fullWidth
        placeholder={placeText}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            backgroundColor: '#FDFDFD',
            '& fieldset': {
              borderColor: '#EBEBEB',
            },
            '&:hover fieldset': {
              borderColor: '#EBEBEB',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#0B5753',
            },
            '& input': {
              outline: 'none',
            },
            width: style.width || '100%',
            height: style.height || '150px',
          },
        }}
        multiline
        rows={4}
        onChange={handleChange}
      />
    </Box>
  );
};

export default TextAreaComponent;
