import { Box, FormControl, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import lang from "../../assets/lang.png";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDefaultLanguage } from '../../store/slice/Addguest.slice';

const LanguageMenuItem = () => {
  const dispatch = useDispatch();  // Get the dispatch function from the Redux store
  const {languageData}=useSelector((store)=>store?.widgetSettings)
  const {defaultLanguage}=useSelector((store)=>store?.addGuest)

  const handleLanguageChange = (event) => {
    const selectedLangCode = event.target.value;
    const selectedLanguage = languageData?.find(
        (item) => item.langcode === selectedLangCode
    );     
    dispatch(updateDefaultLanguage(selectedLanguage?.langcode));   
};
    
  const theme = useTheme();
  return (
    <Box sx={{mr:2}}>
    <FormControl
      variant="outlined"
      sx={{
        minWidth: 120,
        backgroundColor: theme?.widget?.langBgColor || '#fff',
        width: { xs: "100px", md: "120px" },
        height: "45px",
        borderRadius: "5px",
       // border:"1px solid #969BA0",
       
       
      }}
    >
      <Select
        value={defaultLanguage}
        onChange={handleLanguageChange} // Set the onChange handler here
        // IconComponent={() => (
        //     <KeyboardArrowDownIcon
        //         sx={{ color: "black", mr: 1 }}
        //     />
        // )}
        displayEmpty
        renderValue={() => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={lang}
              alt={"lang-img"}
              style={{ width: "40px", marginRight: "10px" }}
            />
          </Box>
        )}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
          },
          "& .MuiOutlinedInput-input": {
              padding: 1,
              //     color: "#9F9F9F",
              fontSize: "12px",
          },
      }}
      >
        {languageData?.map((item, index) => (
          <MenuItem
            key={item?.idlanguage}
            value={item?.langcode}
            sx={{
              color: theme?.palette?.widget?.langTextColor || 'grey', // Text color of MenuItem
              "&.Mui-selected": {
                backgroundColor: theme?.widget?.langActiveBgColor || "orange", // Highlight selected item
              },
              "&:hover": {
                backgroundColor: theme?.widget?.langBgHover || "orange", // Hover effect for MenuItem
                color: theme?.widget?.langTextHover || "#fff",
                "&.Mui-selected": {
                  backgroundColor: theme?.widget?.langBgHover || "orange", // Hover effect for MenuItem
                  color: theme?.widget?.langTextHover || "#fff",
                },
              },
            }}
          >
            {(item?.langcode == defaultLanguage?.trim()) != "" ? (
              <>
                {item?.language}
                <CheckBoxIcon
                  sx={{
                    color: theme?.widget?.langCheckboxActive,
                    marginRight: "8px",
                    ml: 2,
                  }}
                />
              </>
            ) : (
              item?.language
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
  )
}

export default LanguageMenuItem
