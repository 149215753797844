import { useTheme } from "@emotion/react";
import {
  Box,
  FormControl,
  Grid2,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Typography,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers/icons";
import { useDispatch, useSelector } from "react-redux";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import {
  checkGuestLimit,
  setFreeTablesData,
  setGuestCount,
  setIsBackToHoreca,
  setReservationGroupDatas,
  setReservationTypeDatas,
  updateReservationGroup,
  updateReservationType,
  updateSelectedDate,
  updateTime,
  updateTotalCount,
} from "../../store/slice/Addguest.slice";
import { useState } from "react";

const TravelGroup = ({ isOpen, handleClose, Ref }) => {
  const [roomCount, setRoomCount] = useState([{
    roomNum:1,
    adult: 1,
    child: 0,
    baby: 0,
  }]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { adult, baby, child, kid, toddler, isGreaterThan10 } = useSelector(
    (store) => store.addGuest
  );

  const addRooms=()=>{
    const newRoom = { roomNum: roomCount.length + 1, adult: 1, child: 0, baby: 0 };
    setRoomCount([...roomCount, newRoom]);
  }

  const removeRooms = (indexToRemove) => {
    setRoomCount((prev) => prev.filter((_, index) => index !== indexToRemove));
  };
  const updateRoomCount = (index, field, value) => {
    setRoomCount((prev) =>
      prev.map((room, idx) =>
        idx === index ? { ...room, [field]: value } : room
      )
    );
  };
  
  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{ width: "420px", p: 1, pt: 2 }}>
        <Grid2 container spacing={2} p={2}>
          <Grid2 size={{ md: 2.5 }}>
            <Typography sx={{ fontWeight: "bold" }}>Room</Typography>
          </Grid2>
          <Grid2 size={{ md: 2.5 }}>
            <Typography sx={{ fontWeight: "bold" }}>Adult</Typography>
          </Grid2>
          <Grid2 size={{ md: 2.5 }}>
            <Typography sx={{ fontWeight: "bold" }}>Child</Typography>
          </Grid2>
          <Grid2 size={{ md: 2.5 }}>
            <Typography sx={{ fontWeight: "bold" }}>Baby</Typography>
          </Grid2>
        </Grid2>
        {roomCount.map((item, index) => (
          <Grid2 key={index} container spacing={2} p={2} alignItems={"center"}>
            <Grid2 size={{ md: 2.5 }}>
              <Typography sx={{ fontWeight: "bold" }}>{index + 1}</Typography>
            </Grid2>
            <Grid2 size={{ md: 2.5 }}>
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                  height: "32px",

                  position: "relative",
                }}
              >
                {item?.adult > 0 && (
                  <IconButton
                    sx={{
                      position: "absolute",
                      left: isSmallScreen ? -20 : -30,
                      top: "52%",
                      transform: "translateY(-50%)",
                      color: theme?.guest?.textColor || "grey",
                      width: "30px",
                      height: "30px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() => {
                        setRoomCount((prev) =>
      prev.map((room, idx) =>
        idx === index ? { ...room, adult: 0 } : room
      )
    );
                    }}
                  >
                    <ClearIcon sx={{ width: "100%", color: "grey" }} />
                  </IconButton>
                )}

                <Box>
                  <Select
                    id="demo-simple-select"
                    value={item?.adult}
                    size="small"
                    fullWidth
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme?.guest?.dropdownBorderColor,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme?.guest?.dropdownBorderColor,
                      },
                      "& .MuiOutlinedInput-input": {
                        color: theme?.guest?.textColor,
                        fontSize: { sm: "12px" },
                      },
                    }}
                    onChange={(event) => {
                        updateRoomCount(index, "adult", event.target.value)
                    }}
                  >
                    {new Array(10).fill(null).map((_, index) => (
                      <MenuItem
                        key={index}
                        value={index + 1}
                        sx={{
                          color: theme?.guest?.dropdownTextColor,
                          fontSize: { sm: "12px" },
                          "&:hover": {
                            backgroundColor: theme?.guest?.dropdownBgHover,
                            color: theme?.guest?.dropdownTextColorHover,
                          },
                        }}
                      >
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 2.5 }}>
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                  height: "32px",

                  position: "relative",
                }}
              >
                {item?.child > 0 && (
                  <IconButton
                    sx={{
                      position: "absolute",
                      left: isSmallScreen ? -20 : -30,
                      top: "52%",
                      transform: "translateY(-50%)",
                      color: theme?.guest?.textColor || "grey",
                      width: "30px",
                      height: "30px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() => {
                        setRoomCount((prev) =>
                        prev.map((room, idx) =>
                          idx === index ? { ...room, child: 0 } : room
                        ))
                    }}
                  >
                    <ClearIcon sx={{ width: "100%", color: "grey" }}  />
                  </IconButton>
                )}

                <Box>
                  <Select
                    id="demo-simple-select"
                    value={item?.child}
                    size="small"
                    fullWidth
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme?.guest?.dropdownBorderColor,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme?.guest?.dropdownBorderColor,
                      },
                      "& .MuiOutlinedInput-input": {
                        color: theme?.guest?.textColor,
                        fontSize: { sm: "12px" },
                      },
                    }}
                    onChange={(event) => {
                        updateRoomCount(index, "child", event.target.value)
                    }}
                  >
                    {new Array(10).fill(null).map((_, index) => (
                      <MenuItem
                        key={index}
                        value={index + 1}
                        sx={{
                          color: theme?.guest?.dropdownTextColor,
                          fontSize: { sm: "12px" },
                          "&:hover": {
                            backgroundColor: theme?.guest?.dropdownBgHover,
                            color: theme?.guest?.dropdownTextColorHover,
                          },
                        }}
                      >
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 2.5 }}>
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                  height: "32px",

                  position: "relative",
                }}
              >
                {item?.baby > 0 && (
                  <IconButton
                    sx={{
                      position: "absolute",
                      left: isSmallScreen ? -20 : -30,
                      top: "52%",
                      transform: "translateY(-50%)",
                      color: theme?.guest?.textColor || "grey",
                      width: "30px",
                      height: "30px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() => {
                        setRoomCount((prev) =>
                        prev.map((room, idx) =>
                          idx === index ? { ...room, baby: 0 } : room
                        ))
                    }}
                  >
                    <ClearIcon sx={{ width: "100%", color: "grey" }} />
                  </IconButton>
                )}

                <Box>
                  <Select
                    id="demo-simple-select"
                    value={item?.baby}
                    size="small"
                    fullWidth
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme?.guest?.dropdownBorderColor,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme?.guest?.dropdownBorderColor,
                      },
                      "& .MuiOutlinedInput-input": {
                        color: theme?.guest?.textColor,
                        fontSize: { sm: "12px" },
                      },
                    }}
                    onChange={(event) => {
                        updateRoomCount(index, "baby", event.target.value)
                    }}
                  >
                    {new Array(10).fill(null).map((_, index) => (
                      <MenuItem
                        key={index}
                        value={index + 1}
                        sx={{
                          color: theme?.guest?.dropdownTextColor,
                          fontSize: { sm: "12px" },
                          "&:hover": {
                            backgroundColor: theme?.guest?.dropdownBgHover,
                            color: theme?.guest?.dropdownTextColorHover,
                          },
                        }}
                      >
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </FormControl>
            </Grid2>
            
            <Grid2 size={{ md: 2 }}>
              <DeleteOutlineOutlinedIcon sx={{ color: "#F32120",cursor:"pointer" }} onClick={()=>removeRooms(index)} />
            </Grid2>
          </Grid2>
        ))}

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            aria-label="add"
            size="small"
            sx={{
              backgroundColor: "green", // Green background
              color: "white", // White icon color
              width: 30, // Square shape (width and height equal)
              height: 30, // Square shape
              borderRadius: 0,
              "&:hover": {
                backgroundColor: "darkgreen", // Darker green on hover
              },
            }}
          >
            <AddIcon fontSize="inherit"  onClick={addRooms}/>
          </IconButton>
        </Box>
      </Box>
    </Popover>
  );
};

export default TravelGroup;
