import { Box, Typography, useTheme } from "@mui/material";
import React, { forwardRef } from "react"; // Import forwardRef
import "./selectcomponent.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Use forwardRef to forward the ref
const SelectComponent = forwardRef(({ label, value, onClick,disabled,isIcon=false,icon,borderColor='#fff',ishotel }, ref) => {
  const theme =useTheme()
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minWidth: "100px",
        width: "100%",
        height: "70px",
        border: `1px solid ${theme?.widget?.inputBorderColor}` || 'black',
        backgroundColor: theme?.widget?.inputBgColor || '#fff',
        borderRadius: "3px",
        cursor:'pointer',
      }}
      onClick={() => {
        if (disabled) return; // Prevent click action if disabled
        onClick();
      }}
      ref={ref} // Attach the ref here
     
    >
   <Box sx={{display:'flex',alignItems:'center'}}>
    {ishotel && <Box sx={{ml:1}}>
      <CalendarMonthIcon sx={{color:"#F37120"}} />
    </Box>}
      <Box>
        <Typography
          sx={{ fontSize: "12px", color: theme?.widget?.inputTextColor, ml: 2, fontWeight: "200" }}
        >
          {label}
        </Typography>
        <Typography sx={{ fontSize: "16px", color:  theme?.widget?.inputTextColor, ml: 2 }}>
          {value}
        </Typography>
      </Box>
      </Box>
      <Box>
        {
          isIcon ? <CalendarMonthIcon sx={{mr:1}}/> :  <KeyboardArrowDownIcon sx={{ color: theme?.palette?.widget?.Dropdown?.textColor, mr: 2 }} />
        }
       
      </Box>
    </Box>
  );
});

// Export the component
export default SelectComponent;
