import 'react-international-phone/style.css';
import './textinput.css';
import { Box, InputAdornment, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import { PhoneNumberUtil } from 'google-libphonenumber';

const languageToCountryMap = {
  EN: 'us',
  NL: 'nl',
  FR: 'fr',
  DE: 'de',
  ES: 'es',
  IT: 'it',
  ZH: 'CN',
  // Add more languages and their corresponding country codes as needed
};

const phoneUtil = PhoneNumberUtil.getInstance();

export const MuiPhoneComponent = ({
  value,
  onChange,
  style = {},
  error,
  helperText,
  label,
  name,
  ...restProps
}) => {
  const theme = useTheme();
  const { defaultLanguage } = useSelector((store) => store?.addGuest);
  const defaultCountry = languageToCountryMap[defaultLanguage] || 'nl';
  const [validationError, setValidationError] = useState('');
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: defaultCountry,
    value,
    countries: defaultCountries,
  });

  const validatePhoneNumber = useCallback(
    (phoneNumber, iso2) => {
      try {
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber, iso2.toUpperCase());
        if (!phoneUtil.isValidNumberForRegion(number, iso2.toUpperCase())) {
          setValidationError('Invalid phone number for the selected country.');
        } else {
          setValidationError('');
        }
      } catch (error) {
        setValidationError('Invalid phone number format.');
      }
    },
    []
  );

  const debouncedOnChange = useCallback(
    debounce((name, value) => {
      validatePhoneNumber(value, country.iso2);
      onChange(name, value);
    }, 300),
    [onChange, country.iso2, validatePhoneNumber]
  );

  useEffect(() => {
    debouncedOnChange(name, inputValue);
    return () => {
      debouncedOnChange.cancel();
    };
  }, [inputValue, name, debouncedOnChange]);

  const countryOptions = useMemo(
    () =>
      defaultCountries.map((c) => {
        const countryData = parseCountry(c);
        return (
          <MenuItem key={countryData.iso2} value={countryData.iso2}>
            <FlagImage iso2={countryData.iso2} style={{ marginRight: '8px' }} />
            <Typography marginRight="8px">{countryData.name}</Typography>
            <Typography color="gray">+{countryData.dialCode}</Typography>
          </MenuItem>
        );
      }),
    []
  );

  const getMaxPhoneNumberLength = (countryCode) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const exampleNumber = phoneUtil.getExampleNumber(countryCode);
      return phoneUtil.getNumberType(exampleNumber) === phoneUtil.INTERNATIONAL ?
        phoneUtil.getNumberOfLeadingDigits(exampleNumber) :
        phoneUtil.getNumberOfLeadingDigits(phoneUtil.parse(exampleNumber, countryCode));
    } catch (error) {
      console.error("Error parsing phone number", error);
      return 10; // Fallback to reasonable default length if error occurs
    }
  };



  // // Handle phone input change (only phone number part should be editable)
  // const handlePhoneInputChange = (e) => {
  //   const phoneNumber = e.target.value;

  //   // Validate the phone number after change (excluding country code part)
  //   validatePhoneNumber(phoneNumber, country.iso2);

  //   // Call the handler to change the phone number
  //   handlePhoneValueChange(e);

  //   debouncedOnChange(name, phoneNumber);
  // };

  const handlePhoneInputChange = (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    // Extract the phone number and remove non-digit characters
    const phoneNumber = e.target.value.replace(/^\+\d+/, '').replace(/\D/g, '');
  
    try {
      // Get the maximum length for the selected country
      const maxLength = getMaxPhoneNumberLength(country.iso2);
      // If the entered number exceeds the max length, stop processing
      if (phoneNumber.length > maxLength) {
        console.warn("Phone number exceeds the maximum allowed length");
        return;
      }
      // Validate the trimmed phone number
    //  validatePhoneNumber(phoneNumber, country.iso2);
  
      // Update phone value and trigger debounced change
      handlePhoneValueChange(e);
      debouncedOnChange(name, phoneNumber);
    } catch (error) {
      console.error("Error handling phone input:", error);
    }
  };
  

 
  
  
  

  // Handle country selection change
  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);

    // Update the phone number with the new country code if necessary
    const currentPhoneNumber = inputValue.replace(/^\+\d+/, ''); // Remove current country code if any
    onChange(name, `+${phoneUtil.getCountryCodeForRegion(selectedCountry)}${currentPhoneNumber}`);
  };

  // Construct the final phone number with the country code at the front
  const phoneNumberWithCountryCode = `+${phoneUtil.getCountryCodeForRegion(country.iso2)}${inputValue.replace(/^\+\d+/, '')}`;

  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ mb: 2, fontWeight: 'bold', fontSize: '15px', color: theme?.details?.formLabelColor }}>
        {label}
      </Typography>
      <TextField
        id={name}
        autoComplete="off"
        variant="outlined"
        placeholder="Phone number"
        value={phoneNumberWithCountryCode}  // Always display the phone number with the country code
        onChange={(e) => handlePhoneInputChange(e)}  // Allow editing of phone number part
        name={name}
        type="tel"
        inputRef={inputRef}
        error={Boolean(error || validationError)}
        //helperText={helperText || validationError}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px', width: '60px' }}>
              <Select
                MenuProps={{
                  style: { height: '400px', width: '360px', top: '20px', left: '-34px' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
                sx={{
                  width: 'max-content',
                  fieldset: { display: 'none ' },
                  '&.Mui-focused fieldset': { display: 'none !important' },
                  '.MuiSelect-select': { padding: '8px', paddingRight: '24px !important' },
                  svg: { right: 0 },
                }}
                value={country.iso2}
                onChange={handleCountryChange}  // Handle country code change
                renderValue={(value) => <FlagImage iso2={value} style={{ display: 'flex' }} />}
              >
                {countryOptions}
              </Select>
            </InputAdornment>
          ),
        }}
        {...restProps}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            height: '40px',
            borderRadius: '8px',
            backgroundColor: '#FDFDFD',
            '& fieldset': { borderColor: error || validationError ? '#f44336' : '#EBEBEB' },
            '& input': { outline: 'none' },
          },
          ...style,
        }}
      />
    </Box>
  );
};
