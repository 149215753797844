import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid2,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import clock from "../../assets/clock.png";
import user from "../../assets/users.png";
import { cancelReservation, getReservationDetails, getWidgetSettings } from "../../api/HorecaWidget.service";
import { useDispatch, useSelector } from "react-redux";
import { setLanguageWords, setReservationDetail } from "../../store/slice/Addguest.slice";
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { updateWidgetSetting } from "../../store/slice/widgetSetting.slice";

const CancelReservation = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const key = queryParams.get("key");
    const secretKey=queryParams.get("secretkey")
    const navigate=useNavigate()
    const [isLoading,setIsLoading]=useState()
    const [isFetching,setIsFetching]=useState(false)
    const {ReservationDetail}=useSelector((store)=>store?.addGuest)
    const {
      WidgetSetting,
  } = useSelector((store) => store?.widgetSettings);
  //console.log("widgetsetting",WidgetSetting)
  const{
    languageWords,
  }=useSelector((store)=>store?.addGuest)
   const [isChecked,setIsChecked]=useState(true)
   const [error,setError]=useState(false)
   const [reason,setReason]=useState('')
    const DATE= dayjs(ReservationDetail?.arrivalDate).format('DD-MM-YYYY');
    const originalDate = dayjs(DATE, 'DD-MM-YYYY');
    const formattedDate = originalDate?.format('Do [of] MMMM YYYY');
    const dispatch=useDispatch()
    const fetchReservation=async()=>{
        setIsFetching(true)
        try {
            const response =await getReservationDetails(key,secretKey)
            if(response?.status !=200){
              setError(true)
              throw new Error("Failed to fetch reservation details");  
            }
            else{
              const result = response?.data?.basicLanguages?.reduce((acc, item) => {
                const tag = item.tag.replace(/{|}/g, "").replace(/\s+/g, "");
                const data = item.widgetTranslateLanguages[0]?.name;
                acc[tag] = data; // Assign data to the key acc[tag]
                return acc;
            }, {});
              dispatch(setLanguageWords(result));
              dispatch(setReservationDetail(response?.data))
            }

     
        } catch (error) {
            setIsFetching(false)
            console.error("Error fetching reservation details:", error?.message);         
        }
    }
    const fetchWidgetSettings = async (language) => {
      try {
          const response = await getWidgetSettings(language,key);
        //  loadSettings = response;
        if(response?.status !=200 || !response?.data){
          throw new Error("Failed to fetch widget settings");
        }
          dispatch(updateWidgetSetting(response?.data))
          setIsFetching(false)
      } catch (error) {
        setIsFetching(false)
        console.error("Error fetching widget settings:", error?.message);
      }
      };

    const handleCancelReservation=async()=>{
      setIsLoading(true)
        const payload = {
            key: key,
            secretKey: secretKey,
          remarks:reason
    }
        try {
            const response =await cancelReservation(key,secretKey,payload)
            if(response?.status == 200 ){
              navigate('/ReservationCancelSuccess')
            }
            else{
              alert("Error canceling reservation")
            }
            setIsLoading(false)
            
        } catch (error) {
            console.error("Error canceling reservation:", error?.message);     
            setIsLoading(false) 
        }
    }

    const send = (frameHeight, frameWidth, top, right, bottom, left, floating, redirectUrl) => {
        window.parent.postMessage({
            reservationIframeHeight: frameHeight,
            reservationIframeWidth: frameWidth,
            reservationIframePositionTop: top,
            reservationIframePositionRight: right,
            reservationIframePositionBottom: bottom,
            reservationIframePositionLeft: left,
            reservationIframePositionFloating: floating,
            redirectUrl: redirectUrl
        }, '*');
    };



    useEffect(() => {
        document.body.setAttribute('style', 'background-color:  rgb(249, 249, 249) !important');
        fetchReservation()
    },[])

    useEffect(()=>{
      if(ReservationDetail){
        fetchWidgetSettings(ReservationDetail?.language?.slice(-2))
      }
    },[ReservationDetail])

    if(isFetching && !ReservationDetail){
      return (
        <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isFetching}
        onClick={()=> setIsFetching(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop> 
      )
    }
  if(error){
    return(
      <Box
        sx={{
          width: "100%",
          p:1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height:"100vh"
        }}
      >
        <Box sx={{mt:2}} >
          <Typography
            sx={{ color: "#969BA0", fontWeight: "regular", fontSize: "1.5rem" }}
          >
             Failed to Fetch Cancel Reservation details.
          </Typography>
        </Box>
      </Box>
    )
  }

    return (
        send("100vh", "100%", "", "0px", "0px", "", "fixed"),
    <Box
      sx={{
        width: "100%",
        p:1, 
      }}
    >
      <Box sx={{mt:2}} >

        <Box sx={{m:2,mx:3}}>
          <Typography
            sx={{ color: "#00000", fontWeight: "medium", fontSize:'28px' }}
          >
            {languageWords?.CancelReservation}
          </Typography>
          <Typography
            sx={{ color: "#969BA0", fontWeight: "regular", fontSize: '16px'}}
          >
           {languageWords?.CancelInfo} 
          </Typography>
        </Box>
       
        <Grid2 container spacing={2} m={2} >
          <Grid2 size={{ md: 6, sm: 12 }} sx={{width:"100%"}} >
            <Box
              sx={{
                minHeight: "210px",
                border: "1px dashed lightgrey",
                backgroundColor: "#fff",
                borderRadius: "16px",
                width: "100%",
                p:2
              }}
            >
              <Typography
                sx={{
                  color: "#969BA0",
                  fontSize: { md: "24px", sm: "20px", xs: "16px" },
                  fontWeight: "medium",
                }}
              >
               {languageWords?.yourBooking || 'Your Booking'} 
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "medium",
                    fontSize: { md: "22px", sm: "19px", xs: "16px" },
                  }}
                >
                 {WidgetSetting?.find((item) => item?.tag == 'companyName')?.data}
                </Typography>
                <Typography sx={{ color: "#969BA0", fontSize: "10px" }}>
                {WidgetSetting?.find((item) => item?.tag == 'companyAddress')?.data}, {WidgetSetting?.find((item) => item?.tag == 'companyCity')?.data}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                 mt: 1.5,
                  mb: 2,
                }}
              >
                <Box sx={{ width: "24px", height: "24px" }}>
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={clock}
                    alt="time"
                  />
                </Box>
                <Typography sx={{ color: "#969BA0", fontSize: "14px" }}>
                 {formattedDate} {ReservationDetail?.arrivalTime?.substring(0, 5)}
                </Typography>
                <Box
                  sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Box sx={{ width: "24px", height: "24px" }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={user}
                      alt="time"
                    />
                  </Box>
                  <Typography sx={{ color: "#969BA0", fz: "14px" }}>
                  {ReservationDetail?.adults > 0 && `${ReservationDetail?.adults} Adult`}
      {ReservationDetail?.kid > 0 && `, ${ReservationDetail?.kid} Kid`}
      {ReservationDetail?.child > 0 && `, ${ReservationDetail?.child} Child`}
      {ReservationDetail?.toddler > 0  && `, ${ReservationDetail?.toddler} Toddler`}
      {ReservationDetail?.baby > 0  && `, ${ReservationDetail?.baby} Baby`}

                  </Typography>
                </Box>
              </Box>

              {ReservationDetail?.reservationPrice && <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#F37120",
                    fontWeight: "bold",
                  }}
                >
                 {ReservationDetail?.reservationType?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                                  € {parseFloat(ReservationDetail?.reservationType?.maxamount).toFixed(2)}
                </Typography>
              </Box>}
            </Box>
          </Grid2>
          <Grid2 size={{ md: 6, sm: 12 }} sx={{width:"100%"}}>
            <Box
              sx={{
                width: "100%",
                minHeight: "210px",
                border: "1px dashed lightgrey",
                backgroundColor: "#fff",
                borderRadius: "16px",
                p: 3,
              }}
            >
              <Typography
                sx={{
                  color: "#969BA0",
                  fontWeight: "medium",
                  fontSize: { md: "24px", sm: "20px", xs: "16px" },
                }}
              >
              {languageWords?.Payment}
              </Typography>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                  {languageWords?.Total}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                                      € {parseFloat((ReservationDetail?.reservationType?.maxamount / 121) * 100).toFixed(2)} 
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                  {languageWords?.VAT || 'VAT 21%'}  
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                                      € {parseFloat((ReservationDetail?.reservationType?.maxamount)).toFixed(2)} 
                  </Typography>
                </Box>
                {ReservationDetail?.reservationPrice && <Box
                  sx={{
                    borderTop: "1px solid #969BA0",
                    display: "flex",
                    justifyContent: "space-between",
                    pt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#F37120",
                      fontWeight: "medium",
                    }}
                  >
                  {languageWords?.TotalIncludingVAT}  
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#F37120",
                      fontWeight: "medium",
                    }}
                  >
                                      € {parseFloat(ReservationDetail?.reservationType?.maxamount ).toFixed(2)} 
                  </Typography>
                </Box>}
              </Box>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} m={2} >
          <Grid2 size={{ md: 6, sm: 12 }}  sx={{width:"100%",mt:2}}>
            <Box
              sx={{
                width: "100%",
                minHeight: "210px",
                border: "1px dashed lightgrey",
                backgroundColor: "#FDFDFD",
                borderRadius: "16px",
                p: 2,
              }}
            >
              <Typography
                sx={{
                  color: "grey",
                  fontSize: "28px",
                  fontWeight: "medium",
                }}
              >
               {languageWords?.yourDetails} 
              </Typography>
              <Box sx={{ mt: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "medium",
                    }}
                  >
                  {languageWords?.Name}  
                  </Typography>
                  <Typography
                    sx={{
                      color: "#969BA0",
                      fontSize: "15px",
                      fontWeight: "regular",
                    }}
                  >
                  {ReservationDetail?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "medium",
                    }}
                  >
                   {languageWords?.Email} 
                  </Typography>
                  <Typography
                    sx={{
                      color: "#969BA0",
                      fontSize: "15px",
                      fontWeight: "regular",
                    }}
                  >
                    {ReservationDetail?.email}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "2rem", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "medium",
                    }}
                  >
                {languageWords?.Phone}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#969BA0",
                      fontSize: "15px",
                      fontWeight: "regular",
                    }}
                  >
                    {ReservationDetail?.phone}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid2>
          <Grid2 size={{ md: 6, sm: 12 }} sx={{width:"100%",mt:2}}>
            <TextField
              name={reason}
              variant="outlined"
              value={reason} // Controlled input value
              fullWidth
              placeholder={languageWords?.ReasonforCancellation}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  backgroundColor: "#FDFDFD",
                  "& fieldset": {
                    borderColor: "#EBEBEB",
                  },
                  "&:hover fieldset": {
                    borderColor: "#EBEBEB",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0B5753",
                  },
                  "& input": {
                    outline: "none",
                  },
                  width: "100%",
                  minHeight: "210px",
                },
              }}
              multiline
              rows={6}
               onChange={(e)=>setReason(e?.target?.value)} // Update local state and dispatch action
            />
          </Grid2>
        </Grid2>
        <Grid2 container sx={{ m: 2 }}>
          <Grid2 size={{ md: 6,sm:12 }}>
          <FormControlLabel
            control={<Checkbox size="small" name='isChecked' value={isChecked} onChange={()=>setIsChecked(!isChecked)} checked={isChecked}
            sx={{
              "&.Mui-checked": {
                color: isChecked ? "#F37120" : "", // Change color when checked
              },
            }}
            />}
            label={
              <Typography
              sx={{ color: "#969BA0", fontWeight: "regular", fontSize: "16px" }}
            >
             {languageWords?.TermsandCondtionsText} 
            </Typography>
            }
          />
       
          </Grid2>
          <Grid2 size={{ md: 12, sm: 12 }} sx={{mt:2}} >
            <Box sx={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
            <Button
              sx={{ backgroundColor: isChecked ? "#F37120" : 'lightgrey', color:isChecked ? "#FFFFFF" : 'grey', py: 1 }}
              onClick={handleCancelReservation}
              disabled={!isChecked}
            >
              {  
                isLoading ? <CircularProgress 
                size={24} // Adjust size in pixels (example: 24px)
                sx={{ color: "#fff" }} // Custom color for loader
              />
              :languageWords?.CancelWidgetButtonText
              }
             
            </Button>
            </Box>
          </Grid2>
        </Grid2>
      
    
    </Box>
    </Box>
  );
};

export default CancelReservation;
