import { Box, Button, Grid2, useMediaQuery } from '@mui/material';
import React, { useRef, useState } from 'react';
import SelectComponent from '../../components/SelectComponent/SelectComponent';
import TravelGroup from './TravelGroup';
import { useTheme } from '@emotion/react';
import CalendarComponent from './CalenderComponent';
import { useNavigate } from 'react-router-dom';

const HotelWidgetMain = () => {
    const theme=useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [isAddTravelGroup, setIsAddTravelGroup] = useState(false);
    const [isFromDate, setIsFromDate] = useState(false);
    const [isToDate, setIsToDate] = useState(false);
    const travelGroup = useRef(null);
    const fromDate=useRef(null);
    const toDate=useRef(null);
    const navigate=useNavigate()

    return (
        <Box
            sx={{
                width: "100%",
                minHeight: "100vh",
                height: 'auto',
                position: 'relative',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center', // Centers the outer Box content vertically
            }}
        >
            <Box
                sx={{
                    backgroundColor: "lightgrey",
                    minHeight:isSmallScreen ? "100%" : "130px",
                    width:isSmallScreen ? "100%" : "80%",
                    p:isSmallScreen ? 1 : '',
                    position: 'absolute',
                    bottom: "20px",
                    display: 'flex',
                    flexDirection:'column',
                    justifyContent: 'center', // Centers content inside this Box vertically
                }}
            >
                <Grid2
                    container
                    spacing={2}
                    alignItems="center" // Centers Grid2 items vertically
                    justifyContent="center"
                >
                    <Grid2 size={{ lg: 2, md: 4, sm: 6, xs: 12 }}>
                        <SelectComponent
                            label={'Travel Group'}
                            value={'2 Adult, 1 Room'}
                            onClick={() => {
                                setIsAddTravelGroup(!isAddTravelGroup);
                            }}
                            ref={travelGroup}
                        />
                        <TravelGroup
                            isOpen={isAddTravelGroup}
                            handleClose={() => setIsAddTravelGroup(false)}
                            Ref={travelGroup.current}
                        />
                    </Grid2>
                    <Grid2 size={{ lg: 2, md: 4, sm: 6, xs: 12 }}>
                        <SelectComponent
                            label={'From'}
                            value={'17 July 2021'}
                            onClick={() => {
                                setIsFromDate(!isFromDate);
                            }}
                            ref={fromDate}
                            ishotel={true}
                        />
                        <CalendarComponent
                            isOpen={isFromDate}
                            handleClose={() => setIsFromDate(false)}
                            Ref={fromDate.current}
                        />
                    </Grid2>
                    <Grid2 size={{ lg: 2, md: 4, sm: 6, xs: 12 }}>
                    <SelectComponent
                            label={'From'}
                            value={'17 July 2021'}
                            onClick={() => {
                                setIsToDate(!isToDate);
                            }}
                            ref={toDate}
                            ishotel={true}
                        />
                        <CalendarComponent
                            isOpen={isToDate}
                            handleClose={() => setIsToDate(false)}
                            Ref={toDate.current}
                        />
                    </Grid2>
                    <Grid2 size={{ lg: 2, md: 4, sm: 6, xs: 12 }}>
                        <Box sx={{display:"flex",justifyContent:'center',alignItems:'center'}}>
                        <Button sx={{backgroundColor:'#F37120',color:"#fff",width:"164px",height:'64px'}} onClick={()=>navigate('/bookNowHotel')}>Book Now</Button>

                        </Box>
                      
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
    );
};

export default HotelWidgetMain;

