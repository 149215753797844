import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Badge,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import burgerImg from "../../assets/menuBurgerImg.png"
import { useNavigate } from "react-router-dom";
import AddToCart from "./AddToCart";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

const MenuListCardsComponent = ({ data = [] }) => {
  const theme=useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const [open, setOpen] = React.useState(false);
   const {shopType}=useSelector((store)=>store?.widgetSettings)
  const navigate=useNavigate()

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
    // Ensure data is an array
    if (!Array.isArray(data)) {
      console.error("Invalid data prop: expected an array");
      return null; // Render nothing if data is invalid
    }

  
    return (
      <>
      <Box mt={3} px={2}>
        <Grid container spacing={2} justifyContent="center">
          {data.map((item) => (
            <Grid item xs={12} sm={6} md={shopType == 'kiosk' ? 6 : 4} lg={shopType == 'kiosk' ? 6 : 3} key={item.id}
            sx={{
              display: "flex",
              justifyContent: "center", // Center the items horizontally
            }}
            >
              <Card
                sx={{
                width:isSmallScreen ? '100%' : "80%",
                minWidth:"180px",
                maxWidth:"400px",
                  borderRadius: 3,
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  boxShadow: 3,
                }}
               
                
              >
                <Box
                  sx={{
                    width: "100%",
                    height: 'auto',
                    padding: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <img
                    src={burgerImg}
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: 7,
                    }}
                  />
                </Box>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {item.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#F37120", fontWeight: "bold" }}
                    >
                      ${item.price}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary" mb={2}>
                    {item.info}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    mb={2}
                    gap={1}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => console.log("Decrease quantity")}
                      sx={{ border: "none" }}
                    >
                      -
                    </Button>
                    <Typography variant="body1">1</Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => console.log("Increase quantity")}
                      sx={{ border: "none" }}
                    >
                      +
                    </Button>
                  </Box>
                  <Box display="flex" gap={1} flexWrap="wrap">
                    <Badge
                      variant="outlined"
                      sx={{
                        padding: "4px 8px",
                        border: "1px solid #00DC1B",
                        borderRadius: "8px",
                        color: "#00DC1B",
                      }}
                    >
                      {item.type}
                    </Badge>
                    {item.cookingType.map((type, index) => (
                      <Badge
                        key={index}
                        variant="outlined"
                        sx={{
                          padding: "4px 8px",
                          border: "1px solid #9600DC",
                          color: "#9600DC",
                          borderRadius: "8px",
                        }}
                      >
                        {type}
                      </Badge>
                    ))}
                    {item.eatingType.map((type, index) => (
                      <Badge
                        key={index}
                        variant="outlined"
                        sx={{
                          padding: "4px 8px",
                          border: "1px solid #F3D120",
                          borderRadius: "8px",
                          color: "#F3D120",
                        }}
                      >
                        {type}
                      </Badge>
                    ))}
                  </Box>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ backgroundColor: "#F37120" }}
                    onClick={handleClickOpen}
                  >
                    Add to Cart
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <AddToCart open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} />
      </>
    );
  };
  
  export default MenuListCardsComponent;

