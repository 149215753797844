import { Box, InputLabel, TextField, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

const TextInput = ({ label, name, style = {}, value, onChange, error, helperText,placeholder='' }) => {
  const theme=useTheme()
  const [inputValue, setInputValue] = useState(value);
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  // Debounced version of onChange
  const debouncedOnChange = useCallback(
    debounce((name, value) => {
      onChange(name, value);
    }, 300), // Adjust the delay (300ms) as needed
    [onChange]
  );

  useEffect(() => {
    debouncedOnChange(name, inputValue);
    // Cleanup function to cancel the debounce on unmount
    return () => {
      debouncedOnChange.cancel();
    };
  }, [inputValue, debouncedOnChange, name]);

  return (
    <Box sx={{ width: '100%' }}>
      <InputLabel
        sx={{ mb: 2, fontWeight: 'bold', fontSize: '15px', color: theme?.details?.formLabelColor}}
        htmlFor={name} // Changed to use 'name' for accessibility
      >
        {label}
      </InputLabel>
      <TextField
        id={name} // Use 'name' for the id
        name={name}
        placeholder={placeholder}
        variant="outlined"
        fullWidth
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '40px',
            borderRadius: '8px',
            backgroundColor: '#FDFDFD',
            '& fieldset': {
              borderColor: error ? '#f44336' : '#EBEBEB',
            },
            '&:hover fieldset': {
              borderColor: '#EBEBEB',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#EBEBEB',
            },
            '& input': {
              outline: 'none',
              // Style to remove autofill background color
              '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0px 1000px #FDFDFD inset',
                WebkitTextFillColor: 'inherit',
              },
            }
          },
          ...style,
        }}
        value={inputValue}
        onChange={handleChange}
        error={Boolean(error)}
        // helperText={error && `${name} is ${helperText.toLowerCase()}!`}
      />
    </Box>
  );
};

export default TextInput;
