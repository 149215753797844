import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
    setExtraFieldsData,
    setGuestDetailsData,
    setIsBackToHoreca,
    setTransactionDetails,
    toggleBookNowOpen,
    updateDefaultLanguage,
} from "../../store/slice/Addguest.slice";
import {
    CircularProgress,
    Paper,
    StepConnector,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import backicon from "../../assets/backwidget.png";
import nexticon from "../../assets/next.png";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";


import {
    createReservation,
    getExtraFieldDetails,
    getGuestDetails,
    transactionCostForReservation,
} from "../../api/HorecaWidget.service";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Snackbar from "@mui/material/Snackbar";
import dayjs from "dayjs";
import HotelExtras from "./HotelExtras";
import HotelGuests from "./HotelGuests";
import HotelYourDetails from "./HotelYourDetails";
import HotelOverview from "./HotelOverview";
import HotelSent from "./HotelSent";
dayjs?.extend(customParseFormat); // Extend for custom date format parsing

const DottedStepConnector = (props) => (
    <StepConnector
        {...props}
        sx={{
            "& .MuiStepConnector-line": {
                borderColor: "#000000", // Line color
                borderStyle: "dashed", // Dashed line style (simulates dotted with spacing)
                borderWidth: 1, // Line thickness
            },
        }}
    />
);

export default function HotelBookNowForm() {
    const {
        widgetConfig,
        reservType,
        GuestDetailsData,
        extraProductData,
        reservGroup,
        adult,
        child,
        kid,
        baby,
        toddler,
        selectedDate,
        time,
        totalCount,
        selectedExtraProduct,
        transactionDetails,
        guestFields,
        addFields,
        reservationLang,
        combinationWidgetData,
        languageWords,
        defaultLanguage,
        extraFieldsData,
    } = useSelector((store) => store.addGuest);
    const [steps, setSteps] = React.useState([
       'Availability',
       'Your Details',
       'Guests',
       'Extras',
       'Overview',
       'Sent'
    ]);
    const [isLoading, setIsLoading] = React.useState();
    const theme = useTheme();
    const {
        WidgetSetting,
        key,
        floating,
        widgetSetupId,
        buttonPosition,
        widgetPosition,
        resType,
    } = useSelector((store) => store?.widgetSettings);
    const reservationRequestMin =
        parseInt(
            WidgetSetting?.find((item) => item?.tag === "reservationRequest")
        ) ?? 0;
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(1);
    const [completed, setCompleted] = React.useState({ 0: false });
    const { isBookNowOpen } = useSelector((store) => store.addGuest);
    const dispatch = useDispatch();
    const totalSteps = () => steps.length;
    const [state, setState] = React.useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });
    const { vertical, horizontal, open } = state;
    const handleClosebar = () => {
        setState({ ...state, open: true });

        setTimeout(() => {
            setState({ ...state, open: false });
        }, 5000);
    };
    const completedSteps = () => Object.keys(completed).length;
    const isLastStep = () => activeStep === totalSteps() - 1;
    const allStepsCompleted = () => completedSteps() === totalSteps();
    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    const handleBack = () => {
        if (activeStep == 1) {
            dispatch(setIsBackToHoreca(true));
            navigate(-1); 
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        setCompleted({
            ...completed,
            [activeStep]: true,
        });
        handleNext();
    };


    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    // Modal open/close functions
    const handleClickOpen = () => {
        dispatch(toggleBookNowOpen());
    };

    const handleClose = () => {
        dispatch(toggleBookNowOpen());
    };

    const initialValues={};
    const validationSchema = Yup.object().shape({
        // Add your form fields validation here
    });
    const creatBookingReservation=()=>{

    }



    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    // Ensure that form is valid before calling creatBookingReservation
                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    touched,
                    errors,
                    dirty,
                    setFieldTouched,
                    setFieldValue,
                }) => (
                    <Box
                        sx={{
                            backgroundColor: theme?.widget?.stepScreenBgColor,
                            height: '100vh',
                          
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: theme?.widget?.stepScreenBgColor ,
                                minHeight:"100%",
                                p:2

                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%", // Take full width
                                    overflow: "hidden", // Prevent overflow
                                    height: "100%",
                                    px: 2,
                                    mb: 5,
                                }}
                            >
                                <Stepper
                                    nonLinear
                                    activeStep={activeStep}
                                    connector={<DottedStepConnector />}
                                    sx={{
                                        mt: 1,
                                        flexWrap: { xs: "wrap", sm: "nowrap" }, // Wrap steps on small screens
                                        justifyContent: { xs: "center", sm: "flex-start" }, // Center on xs and align to start on sm+
                                    }}
                                >
                                    {steps.map((label, index) => (
                                        <Step key={label} completed={completed[index]}>
                                            <StepButton
                                                sx={{
                                                    ...(activeStep === index && {
                                                        "& .MuiStepLabel-label": {
                                                            color:
                                                                activeStep === index
                                                                    ? theme?.widget?.stepTextColorActive
                                                                    : theme?.widget?.stepTextColor,
                                                            fontWeight: "bold",
                                                            fontSize: "15px",
                                                        },
                                                        "& .MuiStepIcon-root": {
                                                            color:
                                                                activeStep === index
                                                                ? '#F37120'
                                                                : '#00000',
                                                            borderRadius: "50%",
                                                            // Adding the following line for custom icon text color when active
                                                            "& .MuiStepIcon-text": {
                                                                fill:
                                                                    activeStep === index
                                                                        ? '#fff'
                                                                        : '#000000',
                                                            },
                                                        },
                                                    }),
                                                    width: { xs: "100%", sm: "auto" },
                                                    fontSize: { xs: "12px", sm: "inherit" },
                                                }}
                                                // onClick={handleStep(index)}
                                                disabled={true}
                                            >
                                                {isSmallScreen ? "" : label}
                                            </StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                            <div >
                                {allStepsCompleted() ? (
                                    <React.Fragment>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            All steps completed - you're finished
                                        </Typography>
                                        <Box
                                            sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                                        >
                                            <Box sx={{ flex: "1 1 auto" }} />
                                            <Button onClick={handleReset}>Reset</Button>
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                        <React.Fragment>
                                            <Box >
                                            {(() => {
                                                switch (steps[activeStep]) {
                                                    case 'Availability':
                                                        navigate(-1, {
                                                            state: { openWidgetScreen: true },
                                                        });
                                                        break;

                                                    case 'Extras':
                                                        return <HotelExtras />;

                                                    case 'Guests':
                                                        return <HotelGuests />;

                                                    case 'Your Details':
                                                        return (
                                                            <HotelYourDetails />
                                                        );
                                                    case 'Overview':
                                                        return <HotelOverview />;

                                                    case  "Sent":
                                                        return <HotelSent />;

                                                    default:
                                                        return <div>No page found</div>;
                                                }
                                            })()}
                                        </Box>

                                        <Box
                                            sx={{ display: "flex", flexDirection: "row", mt: 1, pb: 10 }}
                                        >
                                            <Button
                                                variant="contained"
                                                //  disabled={activeStep === 1}
                                                onClick={handleBack}
                                                sx={{
                                                    mr: 1,
                                                    backgroundColor: theme?.details?.backButtonBgColor || "grey",
                                                    color: theme?.details?.backButtonTextColor || "#fff",
                                                    "&:hover": {
                                                        backgroundColor:
                                                            theme?.details?.backButtonBgColorHover || "#00000",
                                                        color: theme?.details?.backButtonTextColorHover || "lightgrey",
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img src={backicon} alt="back-img" />
                                                    {languageWords?.Previous || 'Back'}
                                                </Box>
                                            </Button>
                                            <Box sx={{ flex: "1 1 auto" }} />
                                             
                                                <Button
                                                    onClick={() => {
                                                        if (
                                                            steps[activeStep] === 'Overview'
                                                        ) {
                                                            // if (Object.keys(errors).length == 0) {
                                                            //     creatBookingReservation();
                                                            // }
                                                            navigate('/PaymentSuccess')

                                                            // Submit the form when on step 3
                                                        } else {
                                                            handleNext(); // Proceed to the next step otherwise
                                                        }
                                                    }}
                                                    type={
                                                        steps[activeStep] === languageWords?.yourDetails
                                                            ? "submit"
                                                            : "button"
                                                    }
                                                    sx={{
                                                        mr: 1,

                                                        backgroundColor:
                                                            steps[activeStep] === languageWords?.yourDetails
                                                                ? reservType?.totalCost > 0
                                                                    ? theme?.details?.confirmBgColor || '#F37120'
                                                                    : theme?.details?.nextButtonBgColor || '#F37120'
                                                                : theme?.details?.nextButtonBgColor || "#F37120",
                                                        // color:theme?.details?.nextButtonTextColor,
                                                        color:
                                                            steps[activeStep] === languageWords?.yourDetails
                                                                ? reservType?.totalCost > 0
                                                                    ? theme?.details?.confirmTextColor || "#fff"
                                                                    : theme?.details?.nextButtonTextColor || "#fff"
                                                                : theme?.details?.nextButtonTextColor || "#fff",
                                                        "&:hover": {
                                                            backgroundColor:
                                                                theme?.details?.nextButtonBgColorHover,
                                                            color: theme?.details?.nextButtonTextColorHover,
                                                        },
                                                    }}
                                                    disabled={
                                                        Object.keys(errors).length != 0 &&
                                                        steps[activeStep] === languageWords?.yourDetails
                                                    }
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <img src={nexticon} alt="next-img" />
                                                        {isLoading ? (
                                                            <CircularProgress
                                                                size="20px"
                                                                sx={{ color: "#fff" }}
                                                            />
                                                        ) : steps[activeStep] ===
                                                            languageWords?.yourDetails ? (
                                                            reservType?.totalCost > 0 ? (
                                                                languageWords?.ConfirmandPay
                                                            ) : (
                                                                languageWords?.Confirm || 'Sent'
                                                            )
                                                        ) : (
                                                            languageWords?.Next || 'Next'
                                                        )}
                                                    </Box>
                                                </Button>
                                            
                                        </Box>
                                    </React.Fragment>
                                )}
                            </div>
                            {/* </Paper> */}
                        </Box>
                    </Box>
                )}
            </Formik>
            <Box sx={{ width: 500 }}>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={handleClosebar}
                    message="Reservation creation Unsuccessful"
                    key={vertical + horizontal}
                />
            </Box>
        </>
    );
}