import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { getWidgetPositionStyle } from "../../utils/utils";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Paper, Snackbar, StepConnector, useMediaQuery, useTheme } from "@mui/material";
import YourDetails from "./YourDetails";

import backicon from "../../assets/backwidget.png";
import nexticon from "../../assets/next.png";
import { useTranslation } from "react-i18next";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from "dayjs";
import FeedbackDetails from "./FeedbackDetails";
import { createEnquete, getFeedback } from "../../api/feedback.service";
import { setAddFields, setBasicLanguages, updateFeedbackDetails } from "../../store/slice/feedback.slice";
import { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

dayjs?.extend(customParseFormat); // Extend for custom date format parsing

const DottedStepConnector = (props) => (
  <StepConnector
    {...props}
    sx={{
      "& .MuiStepConnector-line": {
        borderColor: "#000000", // Line color
        borderStyle: "dashed", // Dashed line style (simulates dotted with spacing)
        borderWidth: 1, // Line thickness
      },
    }}
  />
);

export default function FeedbackComponent({
  widgetSetupId,
  buttonPosition,
  resType,
    widgetPosition
}) {
  const dispatch = useDispatch();
  //const feedBackDetails=[]
  const {
    feedBackDetails,
    addFields,
    basicLanguages
  } = useSelector((store) => store?.feedback);
console.log(addFields,feedBackDetails,"yyyyys")
  const {
    WidgetSetting,
      key,
      widgetConfig
  }=useSelector((store) => store?.widgetSettings)
  const {isAllowPost,postAnoumous}=useSelector((store)=>store?.feedback)
  const [openWidget,setOpenWidget]=React.useState(false)
  const navigate=useNavigate()
  const [steps, setSteps] = React.useState([
 
  ]);
  const { t, i18n } = useTranslation();
    const theme = useTheme();
    console.log("them",theme?.widget)
   
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const secretkey = queryParams.get('secretkey');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({ 0: false });
  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();
  const [open, setOpen] = React.useState(false);

  const [snackOpen, setSnackOpen] = React.useState(false);

  const handleClick = () => {
    setSnackOpen(true);
  };

  const handleCloseBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
   
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };


  const fetchFeedbackDetails=async()=>{
    try {
      setOpen(true)
        const response = await getFeedback(secretkey)
       if(!response){
        console.log('No feedback found')
        setOpen(false)
        return  // Return if no feedback found to avoid errors
       }
        const result = response?.data?.data?.
        basicLanguages?.reduce((acc, item) => {
          const tagWithoutBraces = item.tag.replace(/[{}]/g, '');
          acc[tagWithoutBraces] = item.widgetTranslateLanguages[0]?.name || '';
          return acc;
        }, {});

        dispatch(setBasicLanguages(result))
        dispatch(updateFeedbackDetails(response?.data?.data))
       setOpen(false)
      
    } catch (error) {
      console.log("Failed to fetch feedback details", error);    
      setOpen(false)
    }
  }

    const send = (
        frameHeight,
        frameWidth,
        top,
        right,
        bottom,
        left,
        floating,
        redirectUrl
    ) => {
        window.parent.postMessage(
            {
                reservationIframeHeight: frameHeight,
                reservationIframeWidth: frameWidth,
                reservationIframePositionTop: top,
                reservationIframePositionRight: right,
                reservationIframePositionBottom: bottom,
                reservationIframePositionLeft: left,
                reservationIframePositionFloating: floating,
                redirectUrl: redirectUrl,
            },
            "*"
        );
    };




  const handleCreteFeedback=async()=>{
    // const payload={
    //   data:{
    //     idcompany:parseInt(feedBackDetails?.idcompany),
    //     idreservation:parseInt(feedBackDetails?.idreservation),
    //         idenquete: parseInt(feedBackDetails?.idenquete),
    //         isAllowPost: isAllowPost,
    //         isPostAnonymously: postAnoumous,
    //     fields:{...addFields}
    //   }
    // }

    const payload={
      data:{
        idcompany:parseInt(feedBackDetails?.idcompany),
        idreservation:parseInt(feedBackDetails?.idreservation),
            idenquete: parseInt(feedBackDetails?.idenquete),
            isAllowPost: isAllowPost,
            isPostAnonymously: postAnoumous,
        fields: Object.values(addFields)
      }
    }
    try {
      const response = await createEnquete(payload)
      if(response?.status == 200){
        navigate('/successFeedback')
      }
      else{
        handleClick()
      
      }
    } catch (error) {
      console.log("Failed to create feedback", error);
     
    }
  }

  useEffect(()=>{
    setSteps([
      basicLanguages?.Feedback,
      basicLanguages['your details'] ,
    ])

  },[basicLanguages])

    useEffect(() => {
        document.body.setAttribute('style', 'background-color:  rgb(249, 249, 249) !important');
    fetchFeedbackDetails()
  },[])


if(open){
  return (
    <Backdrop
    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
    open={open}
    onClick={handleClose}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
  )
}

if( !feedBackDetails || feedBackDetails?.enqueteList?.length <=0 ){
    return (
        send("0px", "0px", "", "0px", "0px", "", "fixed")
  )
}
    return (
        send("100vh", "100%", "", "0px", "0px", "", "fixed"),
    <>
    <Box
    sx={{
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      minHeight:"100vh",
      height:isSmallScreen ? "auto" : "100vh",
      backgroundColor:theme?.widget?.stepScreenBgColor || "rgb(249, 249, 249)",
      py:3,
      px:2
    }}
    >
  
  <Box sx={{width:"100%",height:"100%"}}>
              <Box
                sx={{
                
                  overflow: "hidden", // Prevent overflow
                  px: 2,
                  mb: 5,
                  display:"flex",
                  justifyContent:'center'
                
                }}
              >
                <Box sx={{width:{md:"50%",sm:'100%',xs:'100%'}}}>
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  connector={<DottedStepConnector />}
                  sx={{
                    flexWrap: { xs: "wrap", sm: "nowrap" }, // Wrap steps on small screens
                    justifyContent: { xs: "center", sm: "flex-start" }, // Center on xs and align to start on sm+
                  }}
                >
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      <StepButton
                      
                        sx={{
                          ...(activeStep === index && {
                            "& .MuiStepLabel-label": {
                              color:
                                activeStep === index
                                  ? '#00000'
                                  : 'grey',
                              fontWeight: "bold",
                              fontSize: "15px",
                            },
                            "& .MuiStepIcon-root": {
                              color:
                                activeStep === index
                                  ? '#f37120 !important'
                                  : '#00000',
                              borderRadius: "50%",
                              // Adding the following line for custom icon text color when active
                              "& .MuiStepIcon-text": {
                                fill:
                                  activeStep === index
                                    ? '#fff'
                                    : '#fff',
                              },
                            },
                          }),
                          width: { xs: "100%", sm: "auto" },
                          fontSize: { xs: "12px", sm: "inherit" },
                        }}
                        //onClick={handleStep(index)}
                        disabled={true}
                      >
                        {isSmallScreen ? "" : label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                </Box>
              </Box>
              <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you're finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box sx={{height:"100%"}} >
                      {/* Switch statement for rendering components based on active step */}
                      {(() => {
                        switch (activeStep) {
                          case 0:
                            return <FeedbackDetails
                             />
                          case 1:
                            return <YourDetails />;
                          default:
                            return <div>No page found</div>; // In case of unexpected value
                        }
                      })()}
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      {activeStep != 0 && <Button
                        variant="contained"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{
                          mr: 1,
                          backgroundColor: "#969BA0",
                          color: "white",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img src={backicon} alt="back-img" />
                          {basicLanguages?.Previous}
                        </Box>
                      </Button>}
                      <Box sx={{ flex: "1 1 auto" }} />
                      
                        <Button
                          onClick={() => {
                            if(activeStep == 1){
                              handleCreteFeedback(); // Create feedback if step 1 is completed
                             // navigate("/widget/successFeedback"); // Navigate to thank you page if step 1 is completed
                            }
                              handleNext(); // Proceed to the next step otherwise
                          }}
                          sx={{
                            mr: 1,
                            backgroundColor:  "#F37120" ,
                            color: "white",
                          }}
                           disabled={addFields?.length != feedBackDetails?.enqueteList?.length ||
                             !addFields.every((field) => field.rating > 0) }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img src={nexticon} alt="next-img" />
                            {activeStep == 1 ? basicLanguages?.Send : basicLanguages?.Next}
                          </Box>
                        </Button>
                      
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>       
    </Box>
    <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleCloseBar}
        message="Failed to send the feedback details please try again ."
      />
    </>
  );
}
