import { Box, Typography } from "@mui/material";
import React from "react";
import clock from "../../assets/clock.png";
import user from "../../assets/users.png";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import widgetSettingSlice from "../../store/slice/widgetSetting.slice";
import GroupIcon from '@mui/icons-material/Group';
import BedtimeIcon from '@mui/icons-material/Bedtime';

const BookingCard = () => {

        
    return (
    <Box
      sx={{
        minHeight: "282px",
        border: "1px dashed #F37120",
        backgroundColor: "#FFF8F6",
        borderRadius: "16px",
        p: 2,
        width:"100%"
      }}
    >
      <Typography
        sx={{ color: "#F37120", fontSize:{md:"24px",sm:"20px",xs:'16px'},fontWeight:"medium" }}
            >
               Your Booking
          </Typography>
          <Box sx={{ mt: 2 }}>
        <Typography
          sx={{ color: "#000000", fontWeight: "medium", fontSize: {md:"22px",sm:"19px",xs:'16px'} }}
        >
                 Hotel Name
        </Typography>
      </Box>
     
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          mt: 4,
          mb: 2,
        }}
      >
        <Box sx={{ width: "24px", height: "24px" }}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={clock}
            alt="time"
          />
        </Box>
        <Typography sx={{ color: "#969BA0", fontSize: "14px" }}>
        5th Sep 2024
        </Typography>
       
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          mt: 2,
          
        }}
      >
        <Box sx={{ width: "24px", height: "24px" }}>
        <img
            style={{ width: "100%", height: "100%" }}
            src={user}
            alt="time"
          />
        </Box>
        <Typography sx={{ color: "#969BA0", fontSize: "14px" }}>
        2 Adults, 1 Kid
        </Typography>
       
      </Box>
      <Box sx={{display:"flex",gap:"1rem",alignItems:'center',mt:2}}>
       <BedtimeIcon sx={{ width: "24px", height: "24px",color:"#969BA0" }} />
       <Typography sx={{ color: "#969BA0", fontSize: "14px" }}>1 Night</Typography>
        </Box>
   
     
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Typography
          sx={{ fontSize: "16px", color: "#F37120", fontWeight: "bold" }}
        >
  Special Room
        </Typography>
        <Typography
          sx={{ fontSize: "16px", color: "#F37120", fontWeight: "bold" }}
                >
                    € 250
        </Typography>
      </Box>
      <Typography sx={{ color: "#969BA0", fontSize: "14px",mt:1}}>
        2 Adults, 1 Kid
        </Typography>
   
    </Box>
  );
};

export default BookingCard;