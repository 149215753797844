import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Badge,
  Box,
  Grid,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "@emotion/react";
import burgerImg from "../../assets/menuBurgerImg.png";
import MenuListCardsComponent from "./MenuListCardsComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import TextInput from "../../components/Input/TextInput";
import { useSelector } from "react-redux";
import Completebill from "./Completebill";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function Checkout({ open, handleClose,setOpen }) {
  const theme = useTheme();
  const [completeBillOpen,setCompleteBillOpen]=React.useState(false)
  const {deliveryType}=useSelector((store)=>store?.widgetSettings)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: isSmallScreen || isMediumScreen ? "90%" : "60%", // Set to 90% of the screen width
            maxWidth: "none", // Prevents default Material-UI maxWidth constraints
            height: "auto", // Allows height to adjust dynamically
            minHeight:'500px'
          },
        }}
      >
        <Box>
          <DialogTitle
            id="recommendation-dialog-title"
            sx={{ color: "#F37120", fontWeight: "bold", fontSize: "1.2rem",mb:3 }}
          >
            Checkout
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{mt:2}}>    
        <Grid2 container spacing={2} alignItems={"center"}>
          <Grid2 size={{md:6}}>
            <TextInput name='firstName' placeholder="Type here" label='First Name' onChange={()=>{}} />
          </Grid2>
          <Grid2 size={{md:6}}>
          <TextInput name='lastName' placeholder="Type here"  onChange={()=>{}}   label='Last Name' />
          </Grid2>
          <Grid2 size={{md:6}}>
            <TextInput name='email' placeholder="Type here" label='Email' onChange={()=>{}} />
          </Grid2>
          <Grid2 size={{md:6}}>
          <TextInput name='phone' placeholder="Type here"  onChange={()=>{}}   label='Phone' />
          </Grid2>
          {deliveryType == 'delivery' && <Grid2 size={{md:6}}>
          <TextInput name='address' placeholder="Type here"  onChange={()=>{}}   label='Address' />
          
          </Grid2>}
          {deliveryType == 'delivery' && <Grid2 size={{md:3}}>
          <TextInput name='zipcode' placeholder="Type here"  onChange={()=>{}}   label='Zipcode' />
          </Grid2>}
          {deliveryType == 'delivery' && <Grid2 size={{md:3}}>
          <TextInput name='place' placeholder="Type here"  onChange={()=>{}}   label='Place' />
          </Grid2>}
          {deliveryType == 'delivery' && <Grid2 size={{md:6}}>
          <InputLabel
        sx={{ mb: 2, fontWeight: 'bold', fontSize: '15px', color: theme?.details?.formLabelColor}}
        htmlFor={'time'} // Changed to use 'name' for accessibility
      >
       Requested Delivery Time
      </InputLabel>
          <Select
   // sx={{ width: "100%"}}
    defaultValue="" // Optional: set a default value
    displayEmpty
    sx={{
      width: "100%",
      height: "40px",
      borderRadius: "8px",
      backgroundColor: "#FDFDFD",
      '& .MuiSelect-outlined': {
        padding: '8px', // Adjust padding for the dropdown content
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#EBEBEB", // Default border color
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "#EBEBEB", // Hover border color
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: "#EBEBEB", // Focused border color
      },
    }}
  >
    <MenuItem value="" disabled>
      Select Time
    </MenuItem>
    {Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
      <MenuItem key={number} value={number}>
        {number}
      </MenuItem>
    ))}
  </Select>
          </Grid2>}
          <Grid2 size={{md:deliveryType == 'delivery' ? 6 : 12} } sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>

         <Button variant="contained" sx={{backgroundColor:'#F37120',color:"#fff",py:2,mt:1,width:deliveryType == 'delivery' ?'100%' : '50%' }} 
         onClick={()=>{
          setCompleteBillOpen(true)
          handleClose()
        }}
         >Pay Now</Button>
          </Grid2>
        </Grid2>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
      <Completebill  open={completeBillOpen} handleClose={()=>setCompleteBillOpen(false)} back={()=>setOpen(true)}/>
    </React.Fragment>
  );
}