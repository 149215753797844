import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Badge,
  Box,
  Grid,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "@emotion/react";
import burgerImg from "../../assets/menuBurgerImg.png";
import MenuListCardsComponent from "./MenuListCardsComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import TextInput from "../../components/Input/TextInput";
import { useSelector } from "react-redux";
import Completebill from "./Completebill";
import { MuiPhoneComponent } from "../../components/Input/MuiPhoneComponent";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function PaymentMethod({ open, handleClose}) {
  const navigate=useNavigate()
  const theme = useTheme();
  const [completeBillOpen,setCompleteBillOpen]=React.useState(false)
  const {deliveryType}=useSelector((store)=>store?.widgetSettings)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: isSmallScreen || isMediumScreen ? "90%" : "60%", // Set to 90% of the screen width
            maxWidth: "750px", // Prevents default Material-UI maxWidth constraints
            height: "auto", // Allows height to adjust dynamically
           // minHeight:'500px'
           p:2
          },
        }}
      >
        <Box>
          <DialogTitle
            id="payment-method"
            sx={{ color: "#F37120", fontWeight: "bold", fontSize: "1.2rem" }}
          >
           Payment Method
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent >  
        <Box  >
                      <MuiPhoneComponent
                        style={{ width: "100%" }}
                        label={'Phone'}
                        name={'phone'}
                        value={''}
                        onChange={
                          ()=>{} // Update the value in Formik
                        }
                      />
                    </Box> 
                    <Box>
                        <Typography sx={{color:"#969BA0",fontWeight:"8px",mt:1}}>
                        Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. Molestie mattis adipiscing sit condimentum. Pellentesque donec suscipit amet rhoncus eget ac purus. Nec diam mauris et aliquet sit commodo sagittis.
                        </Typography>
                    </Box>
                    <Box>
                        <Button
                          variant="contained"
                        

                          onClick={()=>   navigate('/webshopSuccess?status=success')}
                          sx={{ mt: 2, width: "100%", fontSize: "1.1rem",backgroundColor:"#F37120" }}
                        >
                         Pay With PIN
                        </Button>
                    </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}