import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Badge,
  Box,
  Grid,
  Grid2,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "@emotion/react";
import burgerImg from "../../assets/menuBurgerImg.png";
import MenuListCardsComponent from "./MenuListCardsComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import PickupType from "./Checkout";
import Checkout from "./Checkout";
import DeletePopup from "./Deletepopup";
import PaymentMethod from "./PaymentMethod";
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// const badgeItem=['Beef','Beef','Grilled','Grilled','Crispy','Crispy','Crispy']
const badgeItem = [
  {
    id: 1,
    name: "Beef",
    color: "#00DC1B",
  },
  {
    id: 2,
    name: "Beef",
    color: "#00DC1B",
  },
  {
    id: 3,
    name: "Grilled",
    color: "#9600DC",
  },
  {
    id: 4,
    name: "Grilled",
    color: "#9600DC",
  },
  {
    id: 5,
    name: "Crispy",
    color: "#F3D120",
  },
  {
    id: 6,
    name: "Crispy",
    color: "#F3D120",
  },
  {
    id: 7,
    name: "Crispy",
    color: "#F3D120",
  },
];

export default function Cartdetails({ open, handleClose }) {
  const {shopType}=useSelector((store)=>store?.widgetSettings)
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [openPickupType,setPickUpType]=React.useState(false);
  const[opendelete,setOpenDelete]=React.useState(false)
  const [openPaymentMethod,setOpenPaymentMethod]=React.useState(false);

  const handleCheckout=()=>{
    shopType == 'kiosk' ?  setOpenPaymentMethod(true) : setPickUpType(true);
    handleClose() 
  }
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: isSmallScreen || isMediumScreen ? "90%" : "60%", // Set to 90% of the screen width
            maxWidth: "none", // Prevents default Material-UI maxWidth constraints
            height: "auto", // Allows height to adjust dynamically
            minHeight:'500px'
          },
        }}
      >
        <Box>
          <DialogTitle
            id="recommendation-dialog-title"
            sx={{ color: "#F37120", fontWeight: "bold", fontSize: "1.2rem",mb:3 }}
          >
            Cart details
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{mt:2}}>
          <Grid2 container spacing={1} alignItems={'center'} >
                <Grid2 size={{ xs: 2 }}>
                  <Box sx={{ width: "100%", borderRadius: 8 }}>
                    <img
                      style={{ width: "100%", borderRadius: 8 }}
                      src={burgerImg}
                      alt="cartimg"
                    />
                  </Box>
                </Grid2>
                <Grid2 size={{ xs: 7 }}>
                  <Box>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      sx={{ color: "#000000", fontWeight: "600" }}
                    >
                      Beef Steak Burger
                    </Typography>
                    <Box
                      sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}
                    >
                      {badgeItem?.map((item) => (
                        <Badge
                          variant="outlined"
                          sx={{
                            padding: "4px 8px",
                            border: `1px solid ${item?.color}`,
                            borderRadius: "8px",
                            color: item?.color,
                            fontSize:{xs:"0.6rem",md:'14px'},
                            width:{md:'68px',xs:"45px"}
                          }}
                        >
                          {item.name}
                        </Badge>
                      ))}
                    </Box>
                    {isSmallScreen && <Typography sx={{mt:2}}>€39.00</Typography>}
                  </Box>
                </Grid2>
                <Grid2 size={{ xs: 2 }}>
            <Select
    sx={{ width: "100%", height: "45px" }}
    defaultValue="" // Optional: set a default value
    displayEmpty
  >
    <MenuItem value="" disabled>
      qty
    </MenuItem>
    {Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
      <MenuItem key={number} value={number}>
        {number}
      </MenuItem>
    ))}
  </Select>
            </Grid2>
            <Grid2 size={{ xs: 1}} >
              <Box sx={{display:'flex',justifyContent:"space-evenly",alignItems:'center'}}>
                {!isSmallScreen && <Typography>€39.00</Typography>}
                <IconButton
                  aria-label="delete-icon"
                  // onClick={handleClose}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                  onClick={()=>setOpenDelete(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid2>
           
            {/* <Grid2 size={{ md: 4 }}>
            <Select
    sx={{ width: "153px", height: "45px" }}
    defaultValue="" // Optional: set a default value
    displayEmpty
  >
    <MenuItem value="" disabled>
      qty
    </MenuItem>
    {Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
      <MenuItem key={number} value={number}>
        {number}
      </MenuItem>
    ))}
  </Select>
            </Grid2>
            <Grid2 size={{ md: 1 }} >
              <Box sx={{display:'flex',justifyContent:"space-evenly",alignItems:'center'}}>
                <Typography>€39.00</Typography>
                <IconButton
                  aria-label="delete-icon"
                  // onClick={handleClose}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                  onClick={()=>setOpenDelete(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid2> */}
          
          </Grid2>
          <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end', // Align items to the right end
      marginTop: '1rem',
      mr:2,
      mt:5
      
    }}
  >
    <Box sx={{ display: 'flex',gap:"3rem",mb:2, alignItems: 'center' }}>
      <Typography>Total</Typography>
      <Typography>€39.00</Typography>
    </Box>
    <Box sx={{ display: 'flex', gap: "3rem",mb:2, alignItems: 'center' }}>
      <Typography>VAT 21%</Typography>
      <Typography>€9.00</Typography>
    </Box>
    <Box sx={{ display: 'flex', gap: "3rem", alignItems: 'center',borderTop:"1px solid black",pt:2 }}>
      <Typography sx={{fontWeight:"bold"}}>Total Including VAT</Typography>
      <Typography sx={{fontWeight:"bold"}}>€48.00</Typography>
    </Box>
    </Box>
    <Grid2 container spacing={2} sx={{pt:2,width:'100%'}} >
        <Grid2 size={{md:6,sm:12,xs:12}} justifyContent={'center'} >
            <Button variant="contained"  size="large" fullWidth sx={{ height: "48px",backgroundColor:"#F37120", color:"#fff"}}>
                Proceed Shopping
            </Button>
        </Grid2>
        <Grid2 size={{md:6,sm:12,xs:12}} >
        <Button variant="contained"  size="large" fullWidth sx={{  height: "48px",backgroundColor:"#F37120", color:"#fff"}} onClick={handleCheckout}>
        Checkout €48.00
            </Button>
        </Grid2>
    </Grid2>
          
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
      <Checkout open={openPickupType} handleClose={()=>setPickUpType(false)} setOpen={setPickUpType} />
      <DeletePopup open={opendelete} handleClose={()=>setOpenDelete(false)}/>
      <PaymentMethod open={openPaymentMethod} handleClose={()=>setOpenPaymentMethod(false)} />
    </React.Fragment>
  );
}
