import { useTheme } from "@emotion/react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TextInput from "../../components/Input/TextInput";
import CountrySelect from "../../components/SelectComponent/SelectCountry";
import { MuiPhoneComponent } from "../../components/Input/MuiPhoneComponent";
import BirthdayComponent from "../../components/SelectComponent/BirthdayComponent";
import BookingCard from "./BookingCard";
import PaymentCard from "./PaymentCard";

const HotelGuests = () => {
  const theme = useTheme();
  const [businessType, setBusinessType] = useState("");
  const [kycNumber, setKycNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [termsandcondition, setTermsandCondition] = useState("");
  return (
    <Box>
      <Typography
        sx={{ textAlign: "center", color: "#000000", fontSize: "32px", mb: 2 }}
      >
       Guest Selection
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 size={{ md: 8 }}>
          <Box sx={{ mt: 1 }}>
            <InputLabel
              sx={{
                fontWeight: "bold",
                fontSize: "28px",
                color: "#000000",
                mb: 3,
              }}
            >
             Special Need Room
            </InputLabel>
            <InputLabel
              sx={{
                mt:1,
                fontSize: "14px",
                color: "#969BA0",
                mb:2,
              }}
            >
             (Hotel Name) Booked for
            </InputLabel>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={businessType}
                value={businessType}
                onChange={(e) => setBusinessType(e.target.value)}
              >
                <FormControlLabel
                  value={"private"}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        "&.Mui-checked": {
                          color: theme?.palette?.themeColor, // Style when checked
                        },
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        color: "#969BA0", // Conditional styling for label
                        fontSize: "16px",
                      }}
                    >
                      I am a gues
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={businessType}
                value={businessType}
                onChange={(e) => setBusinessType(e.target.value)}
              >
                <FormControlLabel
                  value={"public"}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        "&.Mui-checked": {
                          color: "#F37120", // Style when checked
                        },
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        color: "#969BA0", // Conditional styling for label
                        fontSize: "16px",
                      }}
                    >
                     I Booked for someone else
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box sx={{ mt: 3, mb: 1 }}>
            <InputLabel
              sx={{
                fontWeight: "bold",
                fontSize: "28px",
                color: "#000000",
                mb: 3,
              }}
            >
              Contact Data
            </InputLabel>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={businessType}
                value={businessType}
                onChange={(e) => setBusinessType(e.target.value)}
              >
                <FormControlLabel
                  value={"mr"}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        "&.Mui-checked": {
                          color: theme?.palette?.themeColor, // Style when checked
                        },
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        color: "#969BA0", // Conditional styling for label
                        fontSize: "16px",
                      }}
                    >
                      Mr
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={businessType}
                value={businessType}
                onChange={(e) => setBusinessType(e.target.value)}
              >
                <FormControlLabel
                  value={"Mrs"}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        "&.Mui-checked": {
                          color: "#F37120", // Style when checked
                        },
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        color: "#969BA0", // Conditional styling for label
                        fontSize: "16px",
                      }}
                    >
                      Mrs
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Grid2 container spacing={2}>
            <Grid2 size={{ md: 6 }}>
              <TextInput
                label={"First Name"}
                name={"firstName"}
                value={firstName}
                onChange={() => {}}
                // onChange={setField}
                style={{ width: "100%" }}
              />
            </Grid2>
            <Grid2 size={{ md: 6 }}>
              <TextInput
                label={"Last Name"}
                name={"lastName"}
                value={lastName}
                onChange={() => {}}
                // onChange={setField}
                style={{ width: "100%" }}
              />
            </Grid2>
          </Grid2>
          <Box sx={{ mt: 2 }}>
            <TextInput
              label={"email"}
              name={email}
              value={email}
              onChange={() => {}}
              // onChange={setField}
              style={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ mt: 2}} >
                          <FormControlLabel
                            control={
                              <Checkbox size="small" checked={true} onChange={(e) => setTermsandCondition(e.value, e.name)}
                              sx={{
                                mb:3,
                                '&.Mui-checked': {
                                  color: '#F37120', // Checked color
                                }
                              }}
                              />
                            }
                            label={
                              <Typography sx={{color:"#969BA0",mb:2}}>
                                 The Guest can be contacted by E-mail for this reservation.

                              </Typography>
                             }
                             sx={{
                              display:"flex",
                              alignItems: "center", // Align checkbox and label vertically
                              mb: 3, // Adds margin below the label
                            }}
                            labelPlacement="end" // Places label next to the checkbox
                          />
                          
                        </Box>
        </Grid2>
        <Grid2 size={{ md: 4 }}>
          <BookingCard />
          <PaymentCard />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default HotelGuests;

