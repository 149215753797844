import { Box, Card, Grid, IconButton, TextField, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

const ScanWidget = () => {
    const isSmallScreen=useMediaQuery('sm')
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: 2,
      }}
    >
      <Card
        elevation={1}
        sx={{
          width: "90%",
          maxWidth: "900px", // Restrict max width for large screens
          padding: 2,
          display: "flex",
          alignItems: "center",
          py:2
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Text and TextField Section */}
          <Grid item xs={12} md={10} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Grid container  alignItems={'center'}>
                <Grid item xs={12} md={4}>
                <Typography
              sx={{
                fontWeight: "600",
                fontSize: "16px", // Font size adapts for responsiveness
                color: "#000000",
                whiteSpace: "nowrap", // Prevents text wrapping
              }}
            >
              Enter the reservation key:
            </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextField
                        id="reservation-key"
                        label="Reservation Key"
                        variant="outlined"
                        fullWidth
                        sx={{width: "100%"}}
                       
                    />
                </Grid>
            </Grid>
        
          </Grid>

          {/* Buttons Section */}
          <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: isSmallScreen ? "flex-start" : "center", gap: 2 }}>
            <IconButton
              aria-label="save"
              sx={{
                width: "42px",
                height: "45px",
                borderRadius: "7px",
                backgroundColor: "#0086EC",
              }}
            >
              <SaveIcon sx={{ color: "#fff" }} />
            </IconButton>
            <IconButton
              aria-label="fullscreen"
              sx={{
                width: "42px",
                height: "45px",
                borderRadius: "7px",
                backgroundColor: "#F37120",
              }}
            >
              <FullscreenIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default ScanWidget;
