import { Box, Button, Grid2, Typography } from '@mui/material';
import React from 'react';
import backicon from "../../assets/backwidget.png";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useLocation, useNavigate } from 'react-router-dom';
import success from "../../assets/webshopSuccess.png";
import failure from "../../assets/failed.png";
import QRIMG from '../../assets/QRcode.png'
import footerImg from "../../assets/footerLogo.png";
import { useSelector } from 'react-redux';

const WebshopSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const status = queryParams.get('status');
  const {shopType}=useSelector((store)=>store?.widgetSettings)


  return (
    <Box sx={{
        backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      textAlign: 'center',
      p: { xs: 1, sm: 2, md: 3 }, // Responsive padding
    }}>
      {/* Centered Content */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1, // Center vertically in the available space
      }}>
        <Box sx={{
          width: { xs: !status ? '100px' : '150px', sm: !status ? '100px' : '200px', md: !status ? '100px' : '300px' }, // Responsive width
          height: { xs: !status ? '100px' : '100px', sm: !status ? '100px' : '150px', md:!status ? '100px' : '254px' }, // Responsive height
          mb: 2, // Margin bottom for spacing
        }}>
          <img src={status == 'success' ? success : failure} alt="success-img" style={{ width: '100%', height: '100%' }} />
        </Box>
        <Typography sx={{
          color:!status ? "red" : "#F37120",
          fontSize: { xs: '20px', sm: '28px', md: '36px' }, // More responsive scaling
          fontWeight: '600', // Use numeric value for semiBold
          mb: 1,
        }}>
         { !status ? 'Payment failed' : 'Thank you'}
        </Typography>
        <Typography sx={{
          color: "#969BA0",
          fontSize: { xs: '10px', sm: '11px', md: '12px' }, // Responsive font size
          mb: 3,
        }}>
          Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. Molestie mattis adipiscing sit condimentum.
        </Typography>
        {status && <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }, // Stack text on smaller screens
          gap: '1rem',
          alignItems: 'center',
        }}>
          <Typography sx={{
            color: "#000000",
            fontSize: { xs: '14px', sm: '16px' }, // Responsive font size
            fontWeight: '500', // Medium weight
          }}>
            Order Number :
          </Typography>
          <Typography sx={{
            color: "#F37120",
            fontSize: { xs: '28px', sm: '36px', md: '40px' }, // Responsive font size
            fontWeight: '500',
          }}>
            1234567
          </Typography>
        </Box>}
      </Box>
      {
         shopType == 'kiosk' &&  success && 
         <Box>
           <Box sx={{
                display:"flex",
                justifyContent: 'center',
                alignItems: 'center',
             
                width:  '141px', // Responsive width
                height:'134px', // Responsive height
                mb: 2, // Margin bottom for spacing
              }}>
                <img src={QRIMG} alt="QR-img" style={{ width: '100%', height: '100%' }} />
              </Box>
              <Typography sx={{fontSize:'16px',fontWeight:'bold',textAlign:'center',mt:1}}>Add Your Points </Typography>
              <Typography sx={{fontSize:'16px',fontWeight:'bold',textAlign:'center',mb:2}}>To Your Account </Typography>
        </Box>
      }

      {/* Buttons Section at the Bottom */}

      {status == 'success' &&
       <Button
        variant="contained"
        // onClick={() => navigate(-1)}
        sx={{
            backgroundColor:"#F37120",
            color:"#fff",
            mb:1

        }}
      >
        Order agian
      </Button>}
      <Box sx={{display:'flex',flexDirection:'column',gap:"1rem"}}>
      <Button
        variant="contained"
        // onClick={() => navigate(-1)}
        sx={{
            backgroundColor:"grey",
            color:"#fff"

        }}
      >
        Go Back
      </Button>
      {!success && <Button
        variant="contained"
        // onClick={() => navigate(-1)}
        sx={{
            backgroundColor:"#F37120",
            color:"#fff"

        }}
      >
       Start over
      </Button>}

      </Box>
      <Grid2 container mt={4} sx={{width:"100%",pb:2}}> 
      {
       <Grid2 size={{md:1}}>
        <Box
              sx={{
             //   flex: "0 1 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                src={footerImg}
                alt="footerLogo"
                style={{ minWidth: "76px", height: "auto" }}
              />
            </Box>
        </Grid2>}
        <Grid2 size={{md:10}}>
            <Typography sx={{color:"#969BA0",textWrap:"wrap"}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ipsum eget nisi placerat viverra. Donec non ipsum vel nisi fermentum consectetur. Nulla facilisi. Donec id velit vel eros dictum tempor.
            </Typography>
        </Grid2>
    </Grid2>
  
    </Box>
  );
}

export default WebshopSuccess;