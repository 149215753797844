import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Badge, Box, Grid, Grid2, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "@emotion/react";
import burgerImg from "../../assets/menuBurgerImg.png";
import MenuListCardsComponent from "./MenuListCardsComponent";
import Cartdetails from "./CartDetail";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const MenuItemData = [
  {
    id: 1,
    name: "Beef Steak Burger",
    price: "39.00",
    info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    type: "Beef",
    cookingType: ["Grilled", "Grilled"],
    eatingType: ["Crsipy", "Crsipy", "Crispy"],
  },
  {
    id: 2,
    name: "Beef Steak Burger",
    price: "39.00",
    info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    type: "Beef",
    cookingType: ["Grilled", "Grilled"],
    eatingType: ["Crsipy", "Crsipy", "Crispy"],
  },
  {
    id: 3,
    name: "Beef Steak Burger",
    price: "39.00",
    info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    type: "Beef",
    cookingType: ["Grilled", "Grilled"],
    eatingType: ["Crsipy", "Crsipy", "Crispy"],
  },
  {
    id: 4,
    name: "Beef Steak Burger",
    price: "39.00",
    info: "Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. ",
    type: "Beef",
    cookingType: ["Grilled", "Grilled"],
    eatingType: ["Crsipy", "Crsipy", "Crispy"],
  },
];

export default function AddToCart({ open, handleClose }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [recommendationOpen, setRecommendationOpen] = React.useState(false);
  const [nextProduct, setNextProduct] = React.useState(false); // To track showing the next product.
  const [openCart, setOpenCart] = React.useState(false);

  const handleAddToCart = () => {
    setRecommendationOpen(true); // Open the recommendation dialog.
    setNextProduct(true);
   
  };

  const handleRecommendationClose = () => {
    setRecommendationOpen(false);
    setNextProduct(false); // Reset for next use.
  };

  const handleYes = () => {
    setNextProduct(true); // Show next product recommendation.
  };

  const handleNo = () => {
    setRecommendationOpen(false); // Close recommendation dialog.
    setOpenCart(true); // Open the cart dialog.
    handleClose();
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: isSmallScreen || isMediumScreen ? "90%" : "60%", // Set to 90% of the screen width
            maxWidth: "none", // Prevents default Material-UI maxWidth constraints
            height: "auto", // Allows height to adjust dynamically
          },
        }}
      >
        <Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid2 container spacing={2}>
            <Grid2 size={{ md: 6, sm: 12 }}>
              <Box sx={{ width: "100%" }}>
                <img
                  style={{ width: "100%" }}
                  src={burgerImg}
                  alt="menu_item_img"
                />
              </Box>
            </Grid2>
            <Grid2 size={{ md: 6, sm: 12 }}>
              <Box>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Beef Steak Burger
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", color: "#969BA0", mt: 2 }}
                >
                  Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc
                  consectetur eget erat nec.
                </Typography>
              </Box>
              <Grid2 container sx={{ mt: 2 }}>
                <Grid2 size={{ md: 8 }}>
                  <Box sx={{ mb: 1 }}>
                    <Badge
                      variant="outlined"
                      sx={{
                        padding: "4px 8px",
                        border: "1px solid #00DC1B",
                        borderRadius: "8px",
                        color: "#00DC1B",
                      }}
                    >
                      Beef
                    </Badge>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Badge
                      variant="outlined"
                      sx={{
                        padding: "4px 8px",
                        border: "1px solid purple",
                        borderRadius: "8px",
                        color: "purple",
                        mr: 1,
                      }}
                    >
                      Grilled
                    </Badge>
                    <Badge
                      variant="outlined"
                      sx={{
                        padding: "4px 8px",
                        border: "1px solid purple",
                        borderRadius: "8px",
                        color: "purple",
                      }}
                    >
                      Grilled
                    </Badge>
                  </Box>
                  <Box>
                    <Badge
                      variant="outlined"
                      sx={{
                        padding: "4px 8px",
                        border: "1px solid #F3D120",
                        borderRadius: "8px",
                        color: "#F3D120",
                        mr: 1,
                      }}
                    >
                      Crispy
                    </Badge>
                    <Badge
                      variant="outlined"
                      sx={{
                        padding: "4px 8px",
                        border: "1px solid #F3D120",
                        borderRadius: "8px",
                        color: "#F3D120",
                        mr: 1,
                      }}
                    >
                      Crispy
                    </Badge>
                    <Badge
                      variant="outlined"
                      sx={{
                        padding: "4px 8px",
                        border: "1px solid #F3D120",
                        borderRadius: "8px",
                        color: "#F3D120",
                      }}
                    >
                      Crispy
                    </Badge>
                  </Box>
                </Grid2>
                <Grid2 size={{ md: 4 }}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <IconButton size="small">
                        <RemoveIcon />
                      </IconButton>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", mx: 1 }}
                      >
                        1
                      </Typography>
                      <IconButton size="small">
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid2>
              </Grid2>
              <Box>
                <Typography
                  sx={{
                    fontSize: "54px",
                    fontWeight: "bold",
                    color: "#F37120",
                    textAlign:"right"
                  }}
                >
                  €39.00{" "}
                  <small
                    style={{
                      textDecoration: "line-through",
                      fontSize: "0.3em",
                      color: "#969BA0",
                      fontWeight: "normal",
                    }}
                  >
                    €50.00
                  </small>
                </Typography>
                <Button
                  size="large"
                  onClick={handleAddToCart}
                  sx={{
                    backgroundColor: "#F37120",
                    color: "#fff",
                    width: "100%",
                  }}
                  variant="contained"
                  endIcon={<ShoppingCartIcon sx={{ color: "#fff" }} />}
                >
                  Add To Cart
                </Button>
              </Box>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>

      {/* Recommendation Dialog */}
      <BootstrapDialog
        open={recommendationOpen}
        onClose={handleRecommendationClose}
        aria-labelledby="recommendation-dialog-title"
        PaperProps={{
          sx: {
            width: !nextProduct ? "30%" : "90%", // Set to 90% of the screen width
            maxWidth: "none", // Prevents default Material-UI maxWidth constraints
            height: "auto", // Allows height to adjust dynamically
          },
        }}
      >
        <DialogTitle
          id="recommendation-dialog-title"
          sx={{ color: "#F37120", fontWeight: "bold", fontSize: "1.2rem" }}
        >
          Recommended Product
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleNo();
            setNextProduct(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {nextProduct ? (
            <Box sx={{ width: "100%" }}>
              <MenuListCardsComponent data={MenuItemData} />
            </Box>
          ) : (
            <Typography sx={{ textAlign: "center", color: "grey" }}>
              Would you like to explore our recommended product?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              !nextProduct ? handleYes() : handleNo();
            }}
            sx={{ backgroundColor: "#F37120", color: "#fff" }}
          >
            {!nextProduct ? "Yes" : "Next"}
          </Button>
          {!nextProduct && (
            <Button
              onClick={handleNo}
              sx={{ backgroundColor: "grey", color: "#fff" }}
            >
              No
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
      <Cartdetails open={openCart} handleClose={() => setOpenCart(false)} />
    </React.Fragment>
  );
}
