import { Box } from '@mui/material'
import React from 'react'

const TVWidget = () => {
  return (
    <Box>
        TV Widgget
      
    </Box>
  )
}

export default TVWidget
