import { Box } from '@mui/material'
import React from 'react'
import HotelViewComponent from './HotelViewComponent'

const BookNow = () => {
  return (
    <Box sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:'center'}}>
       <HotelViewComponent />
      
    </Box>
  )
}

export default BookNow
