import React from 'react'

const HotelSent = () => {
  return (
    <div>
      Hotel Sent
    </div>
  )
}

export default HotelSent
